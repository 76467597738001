import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const FETCH_ROOT_CATEGORIES_SCOPE = "FETCH_ROOT_CATEGORIES";
const FETCH_IS_CATEGORIES_CHECKED_SCOPE = "FETCH_IS_CATEGORIES_CHECKED";
const GRANT_CATEGORY_SCOPE = "GRANT_CATEGORY";
const FETCH_ALL_CATEGORIES_SCOPE = "FETCH_ALL_CATEGORIES";
const CREATE_CATEGORY_SCOPE = "CREATE_CATEGORY";

export const FETCH_ROOT_CATEGORIES_REQ = createFetchType(
  FETCH_ROOT_CATEGORIES_SCOPE
);
export const FETCH_ROOT_CATEGORIES_ERR = createErrorType(
  FETCH_ROOT_CATEGORIES_SCOPE
);
export const FETCH_ROOT_CATEGORIES_SUCCESS = createSuccessType(
  FETCH_ROOT_CATEGORIES_SCOPE
);
export const FETCH_IS_CATEGORIES_CHECKED_REQ = createFetchType(
  FETCH_IS_CATEGORIES_CHECKED_SCOPE
);
export const FETCH_IS_CATEGORIES_CHECKED_ERR = createErrorType(
  FETCH_IS_CATEGORIES_CHECKED_SCOPE
);
export const FETCH_IS_CATEGORIES_CHECKED_SUCCESS = createSuccessType(
  FETCH_IS_CATEGORIES_CHECKED_SCOPE
);

export const GRANT_CATEGORY_REQ = createFetchType(GRANT_CATEGORY_SCOPE);
export const GRANT_CATEGORY_ERR = createErrorType(GRANT_CATEGORY_SCOPE);
export const GRANT_CATEGORY_SUCCESS = createSuccessType(GRANT_CATEGORY_SCOPE);

export const FETCH_ALL_CATEGORIES_REQ = createFetchType(FETCH_ALL_CATEGORIES_SCOPE);
export const FETCH_ALL_CATEGORIES_ERR = createErrorType(FETCH_ALL_CATEGORIES_SCOPE);
export const FETCH_ALL_CATEGORIES_SUCCESS = createSuccessType(FETCH_ALL_CATEGORIES_SCOPE);

export const CREATE_CATEGORY_REQ = createFetchType(CREATE_CATEGORY_SCOPE);
export const CREATE_CATEGORY_ERR = createErrorType(CREATE_CATEGORY_SCOPE);
export const CREATE_CATEGORY_SUCCESS = createSuccessType(CREATE_CATEGORY_SCOPE);

export const CHANGE_IC_CHECKED_CATEGORY = "CHANGE_IC_CHECKED_CATEGORY";
