import i18next from 'i18next';

export const rejectErrorCodeHelper = (error) => {
  if (error?.response?.data?.Errors) {
    const errorCode = error?.response?.data?.Errors[0]?.Code;
    const errorMessage = errorCode
      ? i18next.t(`apiErrors.${errorCode}`)
      : i18next.t(error.response.data.message);

    return errorMessage;
  }

  return i18next.t(error.response.data.message);
};
