export default {
  app: {
    title: "HR Center",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    close: "Close",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "CONTINUE",
    labelUsername: "Username",
    labelPassword: "Password",
    or: "or",
    and: "and",
    next: "Next",
    nextPage: "Next page",
    previousPage: "Previous page",
    back: "BACK",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    delete: "Delete",
    change: "Change",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "To",
    from: "From",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
    experience: "years of experience",
    noExperience: "No experience",
    firstName: "First name",
    lastName: "Last name",
    gender: "Gender",
    male: "Male",
    female: "Female",
    dateOfBirth: "Date of birth",
    phoneNumber: "Phone number",
    socialNetwork: "The social network",
    address: "address",
    specialist: "Specialist",
    title: "Title",
  },
  login: {
    welcome: "Welcome!",
    firstnameRequired: "An first name is required.",
    lastnameRequired: "A last name is required.",
    emailRequired: "An Email is required.",
    dontHaveAccount: "Don't have an account? ",
    emailFormat: "Invalid email address format.",
    noUsers: "There are no users with that email.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Sign up",
    email: "Please enter your email address or username to log in:",
    logInTitle: "Log In",
    logIn: "Log In",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
    signInWithGoogle: "Continue with google",
    invalidEmail: "Invalid email format",
    specialCharacterRequired:
      "Paswword must contain at least one special character",
    numberRequired: "Paswword must contain at least one number",
    lowercaseRequired: "Paswword must contain at least one lowercase character",
    uppercaseRequired: "Paswword must contain at least one uppercase character",
    numberOfCharactersRequired: "Password must contain at least 8 characters",
    retypePassword: "Please retype your password.",
    matchPasswords: "Your passwords do not match.",
    passwordConfirmation: "Password confirmation",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
  },
  nav: {
    navigation: "Navigation",
    ads: "Ads",
    selectionFlow: "Selection flow",
    candidates: "Candidates",
    planer: "Planer",
    patterns: "Patterns",
    statistics: "Statistics",
    users: "Users",
    signOut: "Sign Out",
    schedule: "Schedule",
    files: "Files",
  },
  ads: {
    dragPdf1: "Drag the document or",
    dragPdf2: "on the computer",
  },
  users: {
    management: "User management",
    fullName: "Name and surname",
    position: "Position",
    invite: "Invite",
    inviteUser: "Invite user",
    regLink: "Registration link",
    receiver: "Receiver",
    user: "User",
    contact: "Contact",
    phone: "Phone",
    socials: "Social Media",
    resetPassword: "Reset password",
    resetLink: "Are you sure you want to send password reset link?",
    disableUser: "Disable user",
    questionDisableUser: "Are you sure you want to disable user?",
    deleteUser: "Delete user",
    questionDeleteUser: "Are you sure you want to delete user?",
    block: "Block",
    unblock: "Unblock",
    profile: "Edit profile",
    positionNotDeclared: "Position has not been declared yet.",
    backToUsers: "Back to list of users",
    noSocialMedia: "User takes not part in any social media.",
    noPhoneNumber: "User has no phone number saved.",
    grantedCategories: "Granted categories",
    applyChanges: "Apply changes",
  },
  registration: {
    phone: "Phone",
    linkedinProfile: "LinkedIn profile",
    position: "Position",
    link: "Registration link",
    register: "Register",
    twoSteps: "Two steps to the HR Center.",
    mistake: "There was a mistake...",
  },
  filters: {
    filters: "Filters",
    experience: "Years of experience",
    technologies: "Technologies",
    employmentType: "Type of employment",
    internship: "Internship",
    work: "Work",
    workHour: "Work hour",
    partTime: "Part-time",
    fullTime: "Full-time",
    search: "Search",
    dateOfApplication: "Date of application",
    category: "Category",
    creationDate: "Creation date",
    advancedTechnologies: "Advanced technologies",
    resetFilters: "Reset filters",
  },
  candidates: {
    search: "Search",
  },
  dialogs: {
    candidateName: "Candidate's name",
    interviewerName: "Interviewer's name (optional)",
    interviewerName2: "Interviewer's name",
    duration: "Duration",
    appointment: "Appointment",
  },
  patterns: {
    editing2: "Editing mode",
  },
  files: {
    addFile: "Add File",
    addFileCategories: "Categories",
    addFileSelectedCategory: "Selected Category",
    addFileSetRoot: "Set root",
    addFileTags: "Tags",
    addFileDragAndDrop: "Drag & Drop File",
    addFileSuccessfullyAddedFile: "Successfully added file",
    addFileGoBackToFiles: "Go back to all files",
    createCategoryModalTitle: "Create category",
    createTagModalTitle: "Create tag",
    createCategoryModalPlaceholder: "Category name",
    createTagModalPlaceholder: "Tag name",
    close: "Close",
    createCategoryModalSubmitButton: "Create category",
    createTagModalSubmitButton: "Create tag",
    folders: "Folders",
    file: "File",
    files: "Files",
    category: "Category",
    root: "Root",
    confirmDialogTitle: "Deleting document",
    confirmDialogContent: "Are you sure for deleting document?",
    editText: "Edit text",
    editTextSub: " | Note",
    saveChanges: "Save changes",
    doucmentPath: "Document path",
    doucmentName: "Document name",
    doucmentType: "Document type",
    doucmentSize: "Document size",
    deleteDocument: "Delete document",
    note: "Note",
    downloadDocument: "Download document",
    filters: "Filters",
    documents: "| Documents",
    extension: "Extension",
    tags: "Tags",
    search: "Search",
    tag: "Tag",
  },
};
