/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import HrLogo from "../../assets/images/hrcenter.png";
import DiligLogo from "../../assets/images/logo_horizontal_black.png";
import jwt_decode from "jwt-decode";
import googleLogo from "../../assets/images/google1.png";
import {
  clearLoginErrors,
  fetchUser,
  fetchGoogleUser,
} from "../../store/actions/login/loginActions";
import { selectLoginError } from "../../store/selectors/loginSelectors";
import { FORGOT_PASSWORD_PAGE, FILES_PAGE } from "../../constants/pages";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Backdrop from "../../components/MUI/BackdropComponent";
import ErrorMessage from "../../components/MUI/ErrorMessageComponent";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import {
  LOGIN_USER_LOADING,
  LOGIN_GOOGLE_USER_LOADING,
} from "../../store/actions/login/loginActionConstants";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
const LoginPage = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const error = useSelector(selectLoginError);

  const [showPassword, setShowPassword] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    function redirectClient() {
      let token = authScopeStringGetHelper(JWT_TOKEN);
      if (!token) {
        return;
      }
      handleApiResponseSuccess();
    }
    redirectClient();
  }, [history]);

  const handleCallbackResponse = (response) => {
    const userObject = jwt_decode(response.credential);
    const user = userObject;
    const token = response.credential;
    dispatch(clearLoginErrors());
    dispatch(
      fetchGoogleUser({
        user,
        token,
        handleApiResponseSuccess,
      })
    );
  };

  const isLoading = useSelector(
    selectIsLoadingByActionType(LOGIN_USER_LOADING)
  );

  const isGoogleLoading = useSelector(
    selectIsLoadingByActionType(LOGIN_GOOGLE_USER_LOADING)
  );

  const handleApiResponseSuccess = () => {
    history.push({
      pathname: FILES_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const handleSubmit = (values) => {
    const { username, password } = values;
    dispatch(clearLoginErrors());
    dispatch(
      fetchUser({
        username,
        password,
        handleApiResponseSuccess,
      })
    );
  };

  useEffect(() => {
    if (isInit) {
      setIsInit(false);
      return;
    }
    try {
      google.accounts.id.initialize({
        client_id:
          "734219382849-nvnulsu7ibfl4bk3n164bgb7c1h5dgca.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: "large",
        width: "250",
      });
    } catch {
      console.log("Google login is not initialized");
    }
  }, [isInit]);

  // const handleGoogleSubmit = (values) => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id:
  //       "734219382849-nvnulsu7ibfl4bk3n164bgb7c1h5dgca.apps.googleusercontent.com",
  //     callback: handleCallbackResponse,
  //   });

  //   google.accounts.id.renderButton(document.getElementById("signInDiv"), {
  //     theme: "outline",
  //     size: "large",
  //   });
  // };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required(t("login.usernameRequired")),
      password: Yup.string().required(t("login.passwordRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Container
      component="main"
      maxWidth="xl"
      className="c-login-container"
      fullwidth="true"
    >
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <Box
        sx={{
          marginTop: 2,
          width: 289,
          height: 684,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={HrLogo} className="login-logo" />
        <Typography variant="h5" sx={{ m: 2, mt: 3 }}>
          {t("login.welcome")}
        </Typography>
        {error && <ErrorMessage error={error} />}
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ position: "relative" }}
        >
          <Backdrop
            position="absolute"
            isLoading={isLoading || isGoogleLoading}
          />
          <TextField
            name="username"
            label={t("common.labelUsername")}
            margin="normal"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            autoFocus
            fullWidth
            inputProps={{ "data-testid": "username-input" }}
          />
          <TextField
            className="rounded-input"
            name="password"
            label={t("common.labelPassword")}
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            fullWidth
            inputProps={{"data-testid":"password-input"}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid container>
            <Grid item sm={12} sx={{ textAlign: "end" }}>
              <Link
                to={FORGOT_PASSWORD_PAGE}
                component={NavLink}
                variant="body2"
                underline="hover"
                className="text-end"
                sx={{ fontSize: 12 }}
              >
                {t("login.forgotYourPassword")}
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            fullWidth
            className="c-btn c-btn--primary"
          >
            {t("login.logIn")}
          </Button>
          <div className="flex-center" data-testid="separator">
            <div className="hr hr-s"></div>
            <div className="hr-mid">{t("common.or")}</div>
            <div className="hr hr-e"></div>
          </div>
          <div>
            {/* <Button
              className="c-btn c-btn--gray flex-center"
              sx={{ width: "100%", mt: 2 }}
              onClick={handleGoogleSubmit}
            >
              <img src={googleLogo} style={{ marginRight: "15px" }} />
              <Typography sx={{ m: 0, p: 0 }} variant="buttonText">
                {t("login.signInWithGoogle")}
              </Typography>
            </Button> */}
            <div id="signInDiv"></div>
          </div>
          <div className="flex-center" data-testid="dilig-logo">
            <img src={DiligLogo} style={{ margin: "70px auto 0px auto" }} />
          </div>
        </Box>
      </Box>
    </Container>
  );
};

LoginPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default LoginPage;
