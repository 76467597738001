import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from "../actionHelpers";

const FILE_FILTERS_SCOPE = "FILE_FILTERS";
export const FILE_FILTERS_REQ = createFetchType(FILE_FILTERS_SCOPE);
export const FILE_FILTERS_ERR = createErrorType(FILE_FILTERS_SCOPE);
export const FILE_FILTERS_SUCCESS = createSuccessType(FILE_FILTERS_SCOPE);
export const FILE_FILTERS_LOADING = createLoadingType(FILE_FILTERS_SCOPE);
export const UPDATE_FILTERS_EXTENSION = 'UPDATE_FILTERS_EXTENSION';
export const UPDATE_FILTERS_TAG = 'UPDATE_FILTERS_TAG';
export const SET_CONTENT = 'SET_CONTENT'
export const RESET_FILTERS = 'RESET_FILTERS'

const FETCH_FILES_SCOPE = "FETCH_FILES";
export const FETCH_FILES_REQ = createFetchType(FETCH_FILES_SCOPE);
export const FETCH_FILES_ERR = createErrorType(FETCH_FILES_SCOPE);
export const FETCH_FILES_SUCCESS = createSuccessType(FETCH_FILES_SCOPE);
export const FETCH_FILES_LOADING = createLoadingType(FETCH_FILES_SCOPE);

const UPDATE_FILE_SCOPE = "UPDATE_FILE";
export const UPDATE_FILE_REQ = createFetchType(UPDATE_FILE_SCOPE);
export const UPDATE_FILE_ERR = createErrorType(UPDATE_FILE_SCOPE);
export const UPDATE_FILE_SUCCESS = createSuccessType(UPDATE_FILE_SCOPE);
export const UPDATE_FILE_LOADING = createLoadingType(UPDATE_FILE_SCOPE);

const DELETE_FILE_SCOPE = "DELETE_FILE"
export const DELETE_FILE_REQ = createFetchType(DELETE_FILE_SCOPE)
export const DELETE_FILE_ERR = createErrorType(DELETE_FILE_SCOPE)
export const DELETE_FILE_SUCCESS = createSuccessType(DELETE_FILE_SCOPE)
