import { all } from "redux-saga/effects";

import loginSaga from "./loginSaga";
import usersSaga from "./usersSaga";
import registerSaga from "./registerSaga";
import categoriesSaga from "./categoriesSaga";
import tagsSaga from "./tagsSaga";
import filesSaga from "./filesSaga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    usersSaga(),
    registerSaga(),
    filesSaga(),
    categoriesSaga(),
    tagsSaga(),
  ]);
}
