import {
    createFetchType,
    createSuccessType,
    createErrorType,
  } from "../actionHelpers";
  
  const UPLOAD_FILE_SCOPE = "UPLOAD_FILE";
  
  export const UPLOAD_FILE_REQ = createFetchType(UPLOAD_FILE_SCOPE);
  export const UPLOAD_FILE_ERR = createErrorType(UPLOAD_FILE_SCOPE);
  export const UPLOAD_FILE_SUCCESS = createSuccessType(UPLOAD_FILE_SCOPE);
  