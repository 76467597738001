import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  uploadFile,
  uploadFileError,
} from "../actions/uploadFile/uploadFileActions";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { UPLOAD_FILE_REQ } from "../actions/uploadFile/uploadFileActionConstants";
import {
  deleteFileRequest,
  updateFileRequest,
  uploadFileRequest,
} from "../../request/filesRequest";
import { getAllFilesReq } from "../../request/fileRequests";
import {
  DELETE_FILE_REQ,
  FETCH_FILES_REQ,
  UPDATE_FILE_REQ,
} from "../actions/files/fileActionConstants";
import {
  deleteFileAction,
  deleteFileError,
  getFileError,
  getFileSuccess,
  updateFileAction,
} from "../actions/files/fileActions";

export function* uploadFileSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("categoryId", payload.categoryId);
    for (let i = 0; i < payload.tagsIds.length; i++)
      formData.append("tagsIds[]", payload.tagsIds[i]);
    formData.append("fileToUpload", payload.fileToUpload);
    const result = yield call(uploadFileRequest, formData);
    yield put(uploadFile(result.data));
    payload.onSuccessUploadFile();
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(uploadFileError(errorMessage));
  }
}

export function* getAll({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);

    const result = yield call(getAllFilesReq, payload.payload);
    yield put(getFileSuccess(result.data));
    if (payload.payload.onSuccess) {
      yield call(payload.payload.onSuccess);
    }
    if (payload.apiSuccess) yield call(payload.apiSuccess);
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(getFileError(errorMessage));
    }
  }
}

export function* updateFileSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(updateFileRequest, { id: payload.id, data: payload.data });
    yield put(updateFileAction(payload));
    payload.onSuccessOpenNoteModal();
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(deleteFileError(errorMessage));
    }
  }
}

export function* deleteFileSaga({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(deleteFileRequest, payload.id);
    yield put(deleteFileAction(payload.id));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(deleteFileError(errorMessage));
    }
  }
}

export default function* filesSaga() {
  yield all([takeLatest(UPLOAD_FILE_REQ, uploadFileSaga)]);
  yield all([takeEvery(FETCH_FILES_REQ, getAll)]);
  yield all([takeEvery(UPDATE_FILE_REQ, updateFileSaga)]);
  yield all([takeLatest(DELETE_FILE_REQ, deleteFileSaga)]);
}
