import { createSelector } from "@reduxjs/toolkit";

export const tagsSelector = (state) => state.tags;

export const selectTags = createSelector(tagsSelector, (state) => state.tags);

export const selectTagsError = createSelector(
  tagsSelector,
  (state) => state.errorMessage
);
