import {
  createClearType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
  createSubmitType,
} from '../actionHelpers';


const LOGIN_USER_SCOPE = 'LOGIN_USER';
export const LOGIN_USER_FETCH = createFetchType(LOGIN_USER_SCOPE);
export const LOGIN_USER_SUCCESS = createSuccessType(LOGIN_USER_SCOPE);
export const LOGIN_USER_ERROR = createErrorType(LOGIN_USER_SCOPE);
export const CLEAR_LOGIN_USER_ERROR = createClearType(
  `${LOGIN_USER_SCOPE}_ERROR`,
);
export const LOGIN_USER_LOADING = createLoadingType(LOGIN_USER_SCOPE);

const LOGIN_GOOGLE_USER_SCOPE = 'LOGIN_GOOGLE_USER';
export const LOGIN_GOOGLE_USER_FETCH = createFetchType(LOGIN_GOOGLE_USER_SCOPE);
export const LOGIN_GOOGLE_USER_SUCCESS = createSuccessType(LOGIN_GOOGLE_USER_SCOPE);
export const LOGIN_GOOGLE_USER_ERROR = createErrorType(LOGIN_GOOGLE_USER_SCOPE);
export const CLEAR_LOGIN_GOOGLE_USER_ERROR = createClearType(
  `${LOGIN_GOOGLE_USER_SCOPE}_ERROR`,
);
export const LOGIN_GOOGLE_USER_LOADING = createLoadingType(LOGIN_GOOGLE_USER_SCOPE);


export const UPDATE_USER_JWT_TOKEN = 'UPDATE_USER_JWT_TOKEN';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

const GENERATE_TOKEN_SCOPE = 'GENERATE_TOKEN';
export const GENERATE_TOKEN = createSubmitType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_SUCCESS = createSuccessType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_ERROR = createErrorType(GENERATE_TOKEN_SCOPE);

const FORGOT_PASSWORD_SCOPE = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD = createSubmitType(FORGOT_PASSWORD_SCOPE);
export const FORGOT_PASSWORD_SUCCESS = createSuccessType(FORGOT_PASSWORD_SCOPE);
export const FORGOT_PASSWORD_ERROR = createErrorType(FORGOT_PASSWORD_SCOPE);

const RESET_PASSWORD_SCOPE = 'RESET_PASSWORD';
export const RESET_PASSWORD = createSubmitType(RESET_PASSWORD_SCOPE);
export const RESET_PASSWORD_SUCCESS = createSuccessType(RESET_PASSWORD_SCOPE);
export const RESET_PASSWORD_ERROR = createErrorType(RESET_PASSWORD_SCOPE);