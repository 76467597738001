import { getRequest, postRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const getUsernames = (emailorusername) =>
  getRequest(apiEndpoints.authentications.getUsernames, {
    emailorusername,
  });

export const attemptLogin = (payload) =>
  postRequest(apiEndpoints.authentications.login, payload);

  export const forgetPasswordEmailSend = (payload) =>
   getRequest(apiEndpoints.authentications.forgetPassword, payload)

export const sendResetPassword = (payload) =>
  postRequest(apiEndpoints.authentications.resetPassword, payload);

export const attemptGoogleLogin = (payload) =>
  postRequest(apiEndpoints.authentications.googleLogin, payload);

export const updateSecurityAnswer = (payload) =>
  postRequest(apiEndpoints.authentications.confirmSecurityQuestion, payload);

export const refreshTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.refreshToken, payload);

export const logoutUserRequest = (userId) =>
{
  postRequest(
    replaceInUrl(apiEndpoints.authentications.logout, {
      userId,
    }),
  );
}
 

export const generateTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.generateToken, payload);
