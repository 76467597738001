import React from "react";
import PropType from "prop-types";
import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import filterIcon from "../../assets/images/filter_vector.png";
import x from "../../assets/images/x.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilesReq,
  updateFileFilterExt,
  updateFileFilterTag,
} from "../../store/actions/files/fileActions";
import { useParams } from "react-router-dom";
import { PAGE_SIZE_FILES } from "../../constants/keyCodeConstants";
import { useTranslation } from "react-i18next";

const DocsFilters = ({ open, handleClose, setPage, setFile }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((s) => s.fileFilters);
  let { id } = useParams();
  const { t } = useTranslation();

  const handleCheckboxesExt = (e) =>
    dispatch(updateFileFilterExt(e.target.value));
  const handleCheckboxesTags = (e) =>
    dispatch(updateFileFilterTag(e.target.value));

  const submitFiltersHandler = (e) => {
    e.preventDefault();

    var extFilters = [];
    filters.extensions
      ?.filter((n) => n.isChecked)
      .forEach((m) => extFilters.push(m.name));

    var tagFilters = [];
    filters.tags
      ?.filter((n) => n.isChecked)
      .forEach((m) => tagFilters.push(m.name));

    dispatch(
      getFilesReq({
        payload: {
          pageSize: PAGE_SIZE_FILES,
          currentPage: 1,
          categoryId: id,
          extensions: extFilters,
          tags: tagFilters,
          content: filters.content === undefined ? "" : filters.content,
          onSuccess: () => {
            setFile(null);
            setPage(1);
          },
        },
        apiSuccess: handleClose,
      })
    );
  };

  const list = () => (
    <Box
      sx={{
        width: 360,
        height: "100%",
        borderRadius: "18px 0 0 18px",
        padding: "36px",
      }}
      role="presentation"
      onKeyDown={handleClose}
    >
      <form onSubmit={submitFiltersHandler}>
        <div data-testid="ad-filters-drawer">
          <div className="ad-filters-header-container">
            <div className="ad-filters-header">
              <img src={filterIcon} alt="filter_icon" />
              {/* <h3>{t("filters.filters")}</h3> */}
              <h3>{t("files.filters")}</h3>
              <p>
                {/* <sub>| {t("ads.ads")}</sub> */}
                <sub>{t("files.documents")}</sub>
              </p>
            </div>
            <div className="ad-filters-header-close" onClick={handleClose}>
              <img src={x} alt="x" />
            </div>
          </div>
          <div
            style={{ paddingTop: "30px" }}
            className="custom-drawer-sub-card-label"
          >
            <p>{t("files.extension")}</p>
          </div>
          <div className="ad-filters-technologies-checkboxes">
            <FormGroup>
              {filters?.extensions?.map((tag, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      onChange={handleCheckboxesExt}
                      value={tag.name}
                      checked={tag.isChecked}
                      className="ad-filters-checkbox"
                    />
                  }
                  label={tag.name}
                />
              ))}
            </FormGroup>
          </div>
          <div
            style={{ paddingTop: "10px" }}
            className="custom-drawer-sub-card-label"
          >
            <p>{t("files.tags")}</p>
          </div>
          <div className="ad-filters-technologies-checkboxes">
            <FormGroup>
              {filters?.tags?.map((tag, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      onChange={handleCheckboxesTags}
                      value={tag.name}
                      checked={tag.isChecked}
                      className="ad-filters-checkbox"
                    />
                  }
                  label={tag.name}
                />
              ))}
            </FormGroup>
          </div>
        </div>
        <div className="custom-drawer-submit">
          <button
            className="c-btn c-btn--primary"
            data-testid="custom-drawer-submit-search"
          >
            {t("files.search")}
          </button>
        </div>
      </form>
    </Box>
  );

  return (
    <div className="docs-filters">
      <Drawer anchor="right" open={open} onClose={handleClose}>
        {list()}
      </Drawer>
    </div>
  );
};

DocsFilters.propTypes = {
  open: PropType.any,
  handleClose: PropType.func,
  setPage: PropType.func,
  setFile: PropType.func,
};

export default DocsFilters;
