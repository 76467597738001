import React from "react";
import { Router } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import history from "./store/utils/history";
import MainContainer from "./components/Section/MainContainer";
import AppRoutes from "./AppRoutes";

/* istanbul ignore file */
function App() {
  return (
    <>
      <Router history={history}>
        <Helmet>
          <title>{i18next.t("app.title")}</title>
        </Helmet>
        <MainContainer>
          <AppRoutes />
        </MainContainer>
      </Router>
    </>
  );
}

export default App;
