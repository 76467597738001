import { all, call, put, takeEvery } from "redux-saga/effects";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import {
  deleteUserRequest,
  enableUserRequest,
  getAllUsers,
  inviteUserRequest,
  userDetailsRequest,
} from "../../request/usersRequest";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import {
  DELETE_USER_REQ,
  FETCH_USERS_REQ,
  TOGGLE_USER_ENABLE_REQ,
  USER_DETAILS_REQ,
  USER_INVITE_REQ,
} from "../actions/users/usersActionConstants";
import {
  deleteStateUser,
  deleteUserError,
  inviteUserError,
  inviteUserSuccess,
  setEnableUsers,
  setEnableUsersError,
  setUsers,
  setUsersError,
  stateUserDetailsSuccess,
  toggleSingleUser,
  userDetailsError,
} from "../actions/users/usersActions";

export function* getUsers() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllUsers);
    yield put(setUsers(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setUsersError(errorMessage));
    }
  }
}

export function* enableUser({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(enableUserRequest, payload.id);
    yield put(setEnableUsers(result.data));
    yield put(toggleSingleUser());
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(setEnableUsersError(errorMessage));
    }
  }
}

export function* deleteUser({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(deleteUserRequest, payload.id);

    // linija koda ispod nece biti potrebna
    // jer nakon brisanja svakako idemo na
    // users page gde se setuje state ponovo sa novim vrednostima
    yield put(deleteStateUser(result.data));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(deleteUserError(errorMessage));
    }
  }
}

export function* userDetails({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(userDetailsRequest, payload.id);
    yield put(stateUserDetailsSuccess(result.data));
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(userDetailsError(errorMessage));
    }
  }
}

export function* invite({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(inviteUserRequest, payload.invite);
    yield put(inviteUserSuccess());
    if (payload.handleApiResponseSuccess) {
      yield call(payload.handleApiResponseSuccess);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(inviteUserError(errorMessage));
    }
  }
}

export default function* usersSaga() {
  yield all([
    takeEvery(FETCH_USERS_REQ, getUsers),
    takeEvery(TOGGLE_USER_ENABLE_REQ, enableUser),
    takeEvery(DELETE_USER_REQ, deleteUser),
    takeEvery(USER_DETAILS_REQ, userDetails),
    takeEvery(USER_INVITE_REQ, invite),
  ]);
}
