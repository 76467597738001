import React from "react";
import planeVector from "../../assets/images/planeVector.png";
import * as Yup from "yup";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { inviteUserReq } from "../../store/actions/users/usersActions";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

const InviteDialog = ({
  title,
  onClose,
  open,
  maxWidth,
  fullWidth,
  responsive,
}) => {
  const dispatch = useDispatch();
  const { isSuccess } = useSelector((s) => s.invite);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const handleSubmit = (values, { resetForm }) => {
    const { lastName, firstName, email } = values;
    dispatch(
      inviteUserReq({
        invite: {
          lastName,
          firstName,
          email,
        },
        handleApiResponseSuccess: resetForm,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(t("login.firstnameRequired")),
      lastName: Yup.string().required(t("login.lastnameRequired")),
      email: Yup.string()
        .required(t("login.emailRequired"))
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("login.invalidEmail")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    handleClose();
  }, [dispatch, isSuccess]);

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={responsive && fullScreen}
      onClose={handleClose}
      open={open}
      style={{
        padding: "36px",
      }}
    >
      <div style={{ padding: "36px" }} data-testid="invite-dialog-container">
        <DialogTitle style={{ padding: 0 }} data-testid="invite-title">
          {title}
        </DialogTitle>
        <DialogContent style={{ padding: "50px 0px 10px 0px" }}>
          <form onSubmit={formik.handleSubmit} data-testid="invite-form">
            {/* <label>Primaoc</label> */}
            <div
              className=""
              style={{
                display: fullScreen ? "" : "flex",
                justifyContent: "center",
                alignItems: "start",
                gap: "15px",
              }}
            >
              <TextField
                // correct way to set test id on mui textfield
                inputProps={{ "data-testid": "invite-input-text" }}
                name="firstName"
                // label={t("users.receiver")}
                label={t("common.firstName")}
                margin="normal"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                fullWidth
              />
              <TextField
                inputProps={{ "data-testid": "invite-input-text" }}
                name="lastName"
                // label={t("users.receiver")}
                label={t("common.lastName")}
                margin="normal"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                fullWidth
              />
            </div>
            <TextField
              inputProps={{ "data-testid": "invite-input-text" }}
              name="email"
              // label={t("users.receiver")}
              label={"E-mail " + t("common.address")}
              margin="normal"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />
            <IconButton
              className={"c-btn--primary c-btn inviteBtn"}
              data-testid="invite-btn"
              sx={{
                width: "100%",
                marginTop: "15px",
              }}
              type="submit"
            >
              <img
                style={{
                  position: "relative",
                  top: 1.25,
                  paddingRight: "15px",
                }}
                src={planeVector}
              />{" "}
              {t("registration.link")}
            </IconButton>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: 0 }}></DialogActions>
      </div>
    </Dialog>
  );
};

InviteDialog.propTypes = {
  title: PropTypes.any,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  fullWidth: PropTypes.bool,
  responsive: PropTypes.bool,
};

export default InviteDialog;
