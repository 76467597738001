import {
  REGISTER_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from "./registerActionConstants";

export const registerRequest = (payload) => ({
  type: REGISTER_REQUEST,
  payload,
});

export const registerError = (payload) => ({
  type: REGISTER_ERROR,
  payload,
});

export const registerSuccess = (payload) => ({
  type: REGISTER_SUCCESS,
  payload,
});
