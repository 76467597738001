import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from "../actionHelpers";

const FETCH_USERS_SCOPE = "FETCH_USERS";
export const FETCH_USERS_REQ = createFetchType(FETCH_USERS_SCOPE);
export const FETCH_USERS_ERR = createErrorType(FETCH_USERS_SCOPE);
export const FETCH_USERS_SUCCESS = createSuccessType(FETCH_USERS_SCOPE);
export const FETCH_USERS_LOADING = createLoadingType(FETCH_USERS_SCOPE);

export const TOGGLE_USER_ENABLE_REQ = "TOGGLE_USER_ENABLE_REQ";
export const TOGGLE_USER_ENABLE_ERR = "TOGGLE_USER_ENABLE_ERR";
export const TOGGLE_USER_ENABLE_SUCCESS = "TOGGLE_USER_ENABLE_SUCCESS";

export const DELETE_USER_REQ = "DELETE_USER_REQ";
export const DELETE_USER_ERR = "DELETE_USER_ERR";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

const USER_DETAILS = "USER_DETAILS";
export const USER_DETAILS_REQ = createFetchType(USER_DETAILS);
export const USER_DETAILS_ERR = createErrorType(USER_DETAILS);
export const USER_DETAILS_SUCCESS = createSuccessType(USER_DETAILS);
export const USER_DETAILS_LOADING = createLoadingType(USER_DETAILS);
export const USER_DETAILS_TOGGLE = "USER_DETAILS_TOGGLE";

export const USER_INVITE_REQ = "USER_INVITE_REQ";
export const USER_INVITE_ERR = "USER_INVITE_ERR";
export const USER_INVITE_SUCCESS = "USER_INVITE_SUCCESS";
