import { createSelector } from "@reduxjs/toolkit";

export const categoriesSelector = (state) => state.categories;

export const selectCategories = createSelector(
  categoriesSelector,
  (state) => state.categories
);

export const selectChildParentRelations = createSelector(
  categoriesSelector,
  (state) => state.childParentRelations
);

export const selectCategoriesError = createSelector(
  categoriesSelector,
  (state) => state.errorMessage
);

export const selectIsCategoriesChecked = createSelector(
  categoriesSelector,
  (state) => state.isCategoriesChecked
);

export const selectIsCategoriesCheckedError = createSelector(
  categoriesSelector,
  (state) => state.errorMessage
);

export const selectChangedCategories = createSelector(
  categoriesSelector,
  (state) => state.changedCategories
);

export const selectAllCategories = createSelector(
  categoriesSelector,
  (state) => state.allCategories
);

export const selectAllCategoriesError = createSelector(
  categoriesSelector,
  (state) => state.errorMessage
);
