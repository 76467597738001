import { IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import userPageBtnIcon from "../../assets/images/userPageBtnIcon.png";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const EditButton = ({ onEnableEdit }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const {t} = useTranslation()

  return (
    <IconButton
      className={"c-btn--primary-outlined edit-btn-class c-btn userPageBtn ml-20px no-padding"}
      onClick={onEnableEdit}
    >
      {!matches && t("patterns.editing2")}
      <img
        style={{
          position: "relative",
          top: -0.25,
          paddingLeft: matches ? "0px" : "10px",
        }}
        src={userPageBtnIcon}
      />
    </IconButton>
  );
};

EditButton.propTypes = {
  onEnableEdit: PropTypes.func,
};

export default EditButton;
