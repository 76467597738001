import React, { useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { useDispatch, useSelector } from "react-redux";
import { setAllCategoriesReq } from "../../store/actions/categories/categoriesAction";
import { selectAllCategories } from "../../store/selectors/categoriesSelector";
import PropTypes from "prop-types";

function TreeViewFiles({ onSelectCategory }) {
  const dispatch = useDispatch();
  const categories = useSelector(selectAllCategories);
  useEffect(() => {
    dispatch(setAllCategoriesReq());
  }, []);

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      onClick={() => onSelectCategory({ id: nodes.id, name: nodes.name })}
    >
      {Array.isArray(nodes.treeViewCategories)
        ? nodes.treeViewCategories.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );
  return categories.length < 0 ? (
    <p>Loading....</p>
  ) : (
    categories.map((category) => (
      <TreeView
        key={category.id}
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          position: "relative",
        }}
      >
        {renderTree(category)}
      </TreeView>
    ))
  );
}

TreeViewFiles.propTypes = {
  onSelectCategory: PropTypes.func,
};

export default TreeViewFiles;
