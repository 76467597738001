import {
  FETCH_TAGS_ERR,
  FETCH_TAGS_SUCCESS,
  CHANGE_TAG_ISCHECKED_VALUE,
  RESET_CHECKED_TAGS,
  CREATE_TAG_ERR,
  CREATE_TAG_SUCCESS,
} from "../../actions/tags/tagsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  tags: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_TAGS_SUCCESS]: setStateTags,
    [FETCH_TAGS_ERR]: setTagsErrorMessage,
    [CHANGE_TAG_ISCHECKED_VALUE]: setIsCheckedTags,
    [RESET_CHECKED_TAGS]: resetIsCheckedTags,
    [CREATE_TAG_SUCCESS]: setCreateTagReducer,
    [CREATE_TAG_ERR]: setCreateTagErrorMessage,
  },
  initialState
);

function setStateTags(state, action) {
  return {
    ...state,
    tags: action.payload,
  };
}

function setTagsErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setIsCheckedTags(state, action) {
  const tmpIndex = state.tags.findIndex((x) => x.id === action.payload);

  if (tmpIndex === -1) {
    return state;
  }

  return {
    ...state,
    tags: state.tags.map((tag, index) =>
      tmpIndex === index ? { ...tag, isChecked: !tag.isChecked } : tag
    ),
  };
}

function resetIsCheckedTags(state) {
  return {
    ...state,
    tags: state.tags.map((tag) => ({ ...tag, isChecked: false })),
  };
}

function setCreateTagReducer(state) {
  return {
    ...state,
  };
}

function setCreateTagErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
