const base = "https://document-organizer-prod.dilig.net/v1";

export default {
  authentications: {
    login: base + "/authentications/authenticate",
    googleLogin: base + "/authentications/authenticateGoogle",
    refreshToken: base + "/authentications/refresh",
    logout: base + "/authentications/logout?userId={userId}",
    forgetPassword: base + "/authentications/ForgotPassword",
    resetPassword: base + "/authentications/RessetPassword",
    register: base + "/authentications/register",
  },
  users: {
    allUsers: base + "/users",
    user: base + "/users/{id}",
    toggleEnabled: base + "/users/toggleEnable/{id}",
    invite: base + "/users/invite",
  },
  candidates: {
    filteredCandidates: base + "/applicants",
    allFilteredAdsCandidates: base + "/applicants/adsApplicants",
    options: base + "/applicants/options",
    initProcess: base + "/applicants/selection-init",
  },
  ads: {
    allAds: base + "/ads",
    createAd: base + "/ads",
    allFilteredAds: base + "/ads/filtered",
    allArchiveAds: base + "/ads/archive",
    adDetails: base + "/ads/details",
    archiveActiveAd: base + "/ads/archive-active-ad/:id",
  },
  technologies: {
    allTechnologies: base + "/technologies",
  },
  comments: {
    addComment: base + "/comments",
  },
  processes: {
    allLevels: base + "/selectionlevels",
    filteredLevels: base + "/selectionlevels/filtered",
    doneProcess: base + "/selectionprocesses",
    getApplicantProcesses: base + "/applicants/processes",
    changeStatus: base + "/selectionprocesses/status-update",
    changeInterviewer: base + "/selectionprocesses/interviewer-update",
    // allProcesses: base + "/selectionprocesses",
  },
  patterns: {
    allPatterns: base + "/patterns",
    patternById: base + "/patterns/:id",
    patternApplicantsById:
      base + "/patterns/corresponding-pattern-applicants/:id",
    filteredPatterns: base + "/patterns/filter",
    createPattern: base + "/patterns",
    updatePattern: base + "/patterns/:id",
    scheduleAppointment: base + "/patterns/schedule-interview",
  },
  stats: {
    stats: base + "/stats",
  },
  schedule: {
    specificSchedule: base + "/schedule",
  },
  applicant: {
    applyForAd: base + "/applicants/apply-for-ad",
    getCV: base + "/applicants/get-CV",
  },
  screeningTest: {
    allTests: base + "/screeningtest",
    createTest: base + "/screeningtest",
  },
  categories: {
    rootCategories: base + "/categories/root-categories",
    isCategoriesChecked: base + "/categories/granted-categories",
    grantCategory: base + "/users/grant-category",
    allCategories: base + "/categories/all-categories",
    createCategory: base + "/categories",
  },
  tags: {
    allTags: base + "/tags/names",
  },
  fileTags: {
    filters: base + "/tags",
  },
  files: {
    uploadFile: base + "/files",
    updateFile: base + "/files/update-note",
    all: base + "/files/filtered",
    deleteFile: base + "/files/delete-file",
  },
};
