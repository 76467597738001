import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LogoutIcon from "@mui/icons-material/Logout";
import React from "react";
import { useTranslation } from "react-i18next";
import avatarLogo from "../../assets/images/Avatar.png";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/actions/login/loginActions";
import IconButton from "../IconButton/IconButton";
import en from "../../assets/images/en.png";
import rs from "../../assets/images/rs.png";

const UserProfile = ({ show, innerRef }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  //   const theme = useTheme();
  //   const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useSelector((s) => s.user);

  const logout = () => {
    dispatch(logoutUser());
  };

  const changeLanguageHandler = () => {
    i18n.language === "en"
      ? i18n.changeLanguage("rs")
      : i18n.changeLanguage("en");
  };

  return (
    <Box className={`user-view flex-center ${show && "active"}`}>
      <div
        ref={innerRef}
        className="flex-center flex-col"
        style={{ width: "90%" }}
      >
        <img src={avatarLogo} width="64px" height="64px" />
        <Typography
          variant="h5"
          sx={{ fontSize: "18px", marginTop: "15px" }}
          className="text-blue"
        >
          {user.firstName + " " + user.lastName}
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: "14px", marginTop: "8px" }}
          className="text-grey9d"
        >
          HR {t("common.specialist")}
        </Typography>
        {/* <div>
          <Button
            type="button"
            variant="contained"
            className="c-btn c-btn--primary"
            onClick={changeLanguageHandler}
          >
            Change Language
          </Button>
        </div> */}
        <div className="change-language">
          <IconButton
            className={`c-btn--primary-outlined c-btn ${
              i18n.language === "rs" && "change-language-active-button"
            }`}
            onClick={changeLanguageHandler}
          >
            <img
              style={{
                position: "relative",
              }}
              src={rs}
            />
            RS
          </IconButton>
          <IconButton
            className={`c-btn--primary-outlined c-btn ${
              i18n.language === "en" && "change-language-active-button"
            }`}
            onClick={changeLanguageHandler}
          >
            <img
              style={{
                position: "relative",
              }}
              src={en}
            />
            EN
          </IconButton>
        </div>
        {/* <div className="hr" style={{ width: "90%", marginTop: "18px" }}></div> */}

        <Button
          sx={{
            padding: "18px 72px",
            border: "1px solid #226cb0",
            borderRadius: "9px",
            background: "white",
            width: "90%",
            // marginTop: "50px",
          }}
          onClick={logout}
          startIcon={<LogoutIcon />}
        >
          {t("nav.signOut")}
        </Button>
      </div>
    </Box>
  );
};
UserProfile.propTypes = {
  show: PropTypes.any,
  innerRef: PropTypes.any,
};
export default UserProfile;
