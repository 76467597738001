import createReducer from "../../utils/createReducer";
import {
  FILE_FILTERS_ERR,
  FILE_FILTERS_SUCCESS,
  UPDATE_FILTERS_EXTENSION,
  UPDATE_FILTERS_TAG,
  SET_CONTENT,
  RESET_FILTERS,
} from "../../actions/files/fileActionConstants";

const initialState = {
  filters: {},
  fetchFileFiltersErrorMessage: "",
};

export default createReducer(
  {
    [FILE_FILTERS_SUCCESS]: setFileFilters,
    [FILE_FILTERS_ERR]: setFileFiltersErrorMessage,
    [UPDATE_FILTERS_EXTENSION]: updateFileFilterExtension,
    [UPDATE_FILTERS_TAG]: updateFileFilterTag,
    [SET_CONTENT]: setContent,
    [RESET_FILTERS]: resetFilters,
  },
  initialState
);

function setFileFilters(state, action) {
  return {
    ...state,
    filters: {
      categories: action.payload.categories.map((n) => ({
        ...n,
        isChecked: false,
      })),
      tags: action.payload.tags.map((n) => ({ ...n, isChecked: false })),
      extensions: action.payload.extensions.map((n) => ({
        name: n,
        isChecked: false,
      })),
    },
  };
}

function setFileFiltersErrorMessage(state, action) {
  return {
    ...state,
    fetchFileFiltersErrorMessage: action.payload,
  };
}

function updateFileFilterExtension(state, action) {
  return {
    ...state,
    filters: {
      ...state.filters,
      extensions: state.filters.extensions?.map((n) =>
        n.name === action.payload ? { ...n, isChecked: !n.isChecked } : n
      ),
    },
  };
}

function updateFileFilterTag(state, action) {
  return {
    ...state,
    filters: {
      ...state.filters,
      tags: state.filters.tags?.map((n) =>
        n.name === action.payload ? { ...n, isChecked: !n.isChecked } : n
      ),
    },
  };
}

function setContent(state, action) {
  return {
    ...state,
    filters: {
      ...state.filters,
      content: action.payload,
    },
  };
}

function resetFilters(state) {
  return {
    ...state,
    filters: {
      tags: state.filters.tags?.map((t) => ({ ...t, isChecked: false })),
      extensions: state.filters.extensions?.map((c) => ({
        ...c,
        isChecked: false,
      })),
    },
  };
}
