import React from "react";
import PropType from "prop-types";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/MUI/NavbarComponent";
import { FormProvider } from "../../context/FormContext";
import { SelectionProvider } from "../../context/SelectionContext";
import { FILES_PAGE } from "../../constants/pages";
// import AppRoutes from "../../AppRoutes";

const urls = [
  "/",
  "/login",
  "/forgot-password",
  "/reset-password",
  "/forgot-password-confirmation",
  "/error-page",
];

const MainContainer = ({ children }) => {
  const { pathname } = useLocation();

  return urls.includes(pathname) ? (
    <div data-testid="default-route-div" className="">{children}</div>
  ) : pathname === "/register" ? (
    <FormProvider>
      <div className="">{children}</div>
    </FormProvider>
  ) : pathname === "/selectionFlow" ? (
    <SelectionProvider>
      <div className="">
        <Navbar />
        <div className="h-withHeader">{children}</div>
      </div>
    </SelectionProvider>
  ) : (
    <div className="">
      <Navbar />
      <div className={pathname === FILES_PAGE ? "" : "h-withHeader"}>
        {children}
      </div>
    </div>
  );
};

MainContainer.propTypes = {
  children: PropType.any,
};

export default MainContainer;
