import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomModal = ({ open, onCloseModal, children, classes }) => {
  const handleClose = () => onCloseModal();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="custom-modal-test-id"
    >
      <Box sx={style} className={`custom-modal ${classes}`}>
        {children}
      </Box>
    </Modal>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool,
  onCloseModal: PropTypes.func,
  children: PropTypes.any,
  classes: PropTypes.string,
};

export default CustomModal;
