import React from "react";
import { useTranslation } from "react-i18next";
import HrLogo from "../../assets/images/hrcenter.png";
import DiligLogo from "../../assets/images/logo_horizontal_black.png";
import { Box, Container, Typography, Link, Grid } from "@mui/material";
import Backdrop from "../../components/MUI/BackdropComponent";
import { BASE_PAGE } from "../../constants/pages";
import { NavLink } from "react-router-dom";

const ForgotPasswordConfirmationPage = () => {
  const { t } = useTranslation();

  return (
    <Container
      component="main"
      maxWidth="xl"
      className="c-login-container"
      fullwidth="true"
    >
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <Box
        sx={{
          marginTop: 2,
          width: 350,
          height: 684,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={HrLogo} className="login-logo" />
        <Typography variant="h5" sx={{ m: 2, mt: 3 }}>
          {t("login.forgotYourPassword")}
        </Typography>
        <Typography variant="p">
          {t("login.forgotYourPasswordConfimation")}
        </Typography>
        <Box component="form" sx={{ position: "relative", mt: 1, p: 1 }}>
          <Backdrop position="absolute" isLoading={false} />
          <Grid container justifyContent="center">
            <Link
              to={BASE_PAGE}
              component={NavLink}
              variant="body2"
              underline="hover"
              data-testid="back-link"
            >
              {t("login.forgotYourPasswordBackLink")}
            </Link>
          </Grid>
          <div className="flex-center" data-testid="dilig-logo">
            <img src={DiligLogo} style={{ margin: "70px auto 0px auto" }} />
          </div>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPasswordConfirmationPage;
