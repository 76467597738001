import React, { useState, useMemo, useRef, useEffect } from "react";
import {
  AppBar,
  // Badge,
  Box,
  IconButton,
  Toolbar,
  Typography,
  List,
  // ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Button,
} from "@mui/material";
import HrLogo from "../../assets/images/HR.png";
// import x from "../../assets/images/x.png";
import avatarLogo from "../../assets/images/Avatar.png";
import { useTheme } from "@mui/system";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
// import Brightness4Icon from "@mui/icons-material/Brightness4";
// import Brightness7Icon from "@mui/icons-material/Brightness7";
// import MenuList from "./MenuListComponent";
import Drawer from "./DrawerComponent";
// import { ColorModeContext } from "../../context/ColorModeContext";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import UserProfile from "../Profile/UserProfile";
import { useDispatch, useSelector } from "react-redux";
//import { userSelector } from "../../store/selectors/userSelectors";
import { Link, useLocation } from "react-router-dom";
import rs from "../../assets/images/rs.png";
import en from "../../assets/images/en.png";
import { logoutUser } from "../../store/actions/login/loginActions";

const NavbarComponent = () => {
  const navItems = ["users", "files"];
  const { pathname } = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [preview, setPreview] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  // const toggleColorMode = useContext(ColorModeContext);

  const logout = () => {
    dispatch(logoutUser());
  };

  let userRef = useRef();
  let btnRef = useRef();

  // get authenticated user
  const { user } = useSelector((s) => s.user);

  const { t, i18n } = useTranslation();

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const changeLanguageHandler = () => {
    i18n.language === "en"
      ? i18n.changeLanguage("rs")
      : i18n.changeLanguage("en");
  };

  const userPreviewHandler = () => {
    setPreview(!preview);
  };

  const filterNavItems = () => {
    return navItems.filter(
      (n) => (n === "users" && user.role === "SuperAdmin") || n !== "users"
    );
  };

  useEffect(() => {
    let handler = (e) => {
      if (userRef.current) {
        if (
          !userRef.current.contains(e.target) &&
          !btnRef.current.contains(e.target)
        ) {
          setPreview(false);
        }
      }
    };

    document.addEventListener("mousedown", handler, { capture: true });

    return () => {
      document.removeEventListener("mousedown", handler, { capture: true });
    };
  }, []);

  const drawerContent = useMemo(
    () => (
      <div style={{ width: "100vw", padding: "36px" }}>
        <div
          className="flex-center"
          style={{ justifyContent: "space-between", marginBottom: "20px" }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "18px", letterSpacing: "1.5px" }}
          >
            {t("nav.navigation")}
          </Typography>
          <IconButton onClick={handleToggleDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="flex-center flex-col">
          <img src={avatarLogo} width="64px" height="64px" />
          <Typography
            variant="h5"
            sx={{ fontSize: "18px", marginTop: "15px" }}
            className="text-blue"
          >
            {user.firstName + " " + user.lastName}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", marginTop: "8px" }}
            className="text-grey9d"
          >
            HR {t("common.specialist")}
          </Typography>
          <div className="change-language">
            <IconButton
              className={`c-btn--primary-outlined c-btn ${
                i18n.language === "rs" && "change-language-active-button"
              }`}
              onClick={changeLanguageHandler}
            >
              <img
                style={{
                  position: "relative",
                }}
                src={rs}
              />
              RS
            </IconButton>
            <IconButton
              className={`c-btn--primary-outlined c-btn ${
                i18n.language === "en" && "change-language-active-button"
              }`}
              onClick={changeLanguageHandler}
            >
              <img
                style={{
                  position: "relative",
                }}
                src={en}
              />
              EN
            </IconButton>
          </div>
          <div className="hr" style={{ width: "90%", marginTop: "18px" }}></div>
        </div>
        <List>
          {filterNavItems().map((n) => (
            <ListItemButton key={n} onClick={handleToggleDrawer}>
              <ListItemIcon>
                <ListItemText
                  variant="body1"
                  sx={{
                    // marginRight: "50px",
                    cursor: "pointer",
                    textAlign: "left",
                    lineHeight: "17.6px",
                    fontWeight: "400",
                    color: "#353535",
                    marginY: "0",
                    padding: "0",
                    textDecoration: "none",
                  }}
                  className="text-black"
                  as={Link}
                  to={`/${n}`}
                >
                  {t("nav." + n)}
                </ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ))}
          {/* <ListItem divider>
            <IconButton onClick={toggleColorMode}>
              <ListItemText>Toggle {theme.palette.mode} mode</ListItemText>
              {theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </ListItem> */}
        </List>
        {/* <button style={{width:'90%', margin: 'auto'}} className="flex-center"> */}
        {/* <Typography>s</Typography> */}
        <div className="flex-center">
          <Button
            onClick={logout}
            sx={{
              padding: "18px 72px",
              border: "1px solid #226cb0",
              borderRadius: "9px",
              background: "white",
              width: "90%",
              marginTop: "50px",
            }}
            startIcon={<LogoutIcon />}
          >
            {t("nav.signOut")}
          </Button>
        </div>
      </div>
    ),
    [handleToggleDrawer]
  );

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "transparent",
        position: "relative",
        width: "100%",
        padding: "0",
      }}
    >
      <Toolbar
        sx={{
          padding: "0px",
          margin: "0px",
          width: "100%",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0px",
            margin: "0px",
            // md: {paddingX: "72px"}
            // paddingX: "72px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: matches ? "100%" : "auto",
            }}
          >
            {matches ? (
              <Box
                className="responsive-nav-cont"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  style={{ height: "37px", width: "37px", marginLeft: "0px" }}
                  src={HrLogo}
                  className="responsive-logo"
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="icons-cont"
                >
                  {/* <img src={searchIcon} /> */}
                  <IconButton
                    sx={{ marginLeft: "27px" }}
                    onClick={handleToggleDrawer}
                  >
                    <MenuOutlinedIcon />
                  </IconButton>
                </div>
              </Box>
            ) : (
              <Box>
                {/* <IconButton onClick={handleToggleDrawer}>
                  <MenuOutlinedIcon />
                </IconButton> */}
                <img
                  style={{ height: "37px", width: "37px", marginLeft: "72px" }}
                  src={HrLogo}
                />
              </Box>
            )}
          </Box>
          {matches ? (
            <Drawer
              open={openDrawer}
              toggleOpen={handleToggleDrawer}
              content={drawerContent}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingRight: "20px",
                justifyContent: "flex-end",
                // HEIGHT OF THE NAVBAR IN md & xl SIZE
                width: "1076px",
                borderBottom: "1px solid #F4F4F4",
                boxSizing: "border-box",
              }}
              className="navLinks-cont"
            >
              {filterNavItems().map((n) => (
                <Typography
                  variant="body1"
                  key={n}
                  sx={{
                    marginRight: "50px",
                    cursor: "pointer",
                    textAlign: "right",
                    lineHeight: "20.11px",
                    fontWeight: "400",
                    // color: "text.primary",
                    marginY: "0",
                    padding: "0",
                    textDecoration: "none",
                  }}
                  className={pathname === `/${n}` ? "text-blue" : "text-black"}
                  as={Link}
                  to={`/${n}`}
                >
                  {t("nav." + n)}
                </Typography>
              ))}
              <div
                onClick={userPreviewHandler}
                className="full-rounded user-avatar text-uppercase"
                ref={btnRef}
              >
                {/* user.profilePic ? user.profilePic : */}
                {user.firstName[0] + "" + user.lastName[0]}
              </div>
            </Box>
          )}
          {!matches && <UserProfile innerRef={userRef} show={preview} />}
          {/* <Box>
            <MenuList />
          </Box> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavbarComponent;
