import { useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import {
	authScopeSetHelper,
	authScopeStringGetHelper,
} from '../util/helpers/authScopeHelpers';

const useToggleColorMode = () => {
	const currentColorMode = authScopeStringGetHelper('colorMode') || 'light';
	const [mode, setMode] = useState(currentColorMode);

	const toggleColorMode = () => {
		const nextMode = mode === 'light' ? 'dark' : 'light';
		setMode(nextMode);
		authScopeSetHelper('colorMode', nextMode);
	};

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode,
				},
			}),
		[mode]
	);

	return [toggleColorMode, theme];
};

export default useToggleColorMode;
