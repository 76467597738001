import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "../../components/IconButton/IconButton";
import {
  createCategoryReq,
  setCategoriesReq,
} from "../../store/actions/categories/categoriesAction";
import { createTagReq } from "../../store/actions/tags/tagsAction";
import {
  selectCategories,
  selectChildParentRelations,
} from "../../store/selectors/categoriesSelector";
import table from "../../assets/images/table.png";
import { FILES_PAGE } from "../../constants/pages";
import FileTable from "./FileTable";
import { useParams } from "react-router-dom";
import { ADD_FILE } from "../../constants/pages";
import CustomModal from "../../components/UI/CustomModal";
import xIcon from "../../assets/images/x.png";
import plusIcon from "../../assets/images/plus.png";
import Button from "../../components/Button/Button";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilesPage = ({ history }) => {
  const categories = useSelector(selectCategories);
  const childParentRelations = useSelector(selectChildParentRelations);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(0);
  const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false);
  const [openCreateTagModal, setOpenCreateTagModal] = useState(false);
  const [createCategoryName, setCreateCategoryName] = useState("");
  const [createTagName, setCreateTagName] = useState("");
  let { id } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("370"));
  const { t } = useTranslation();

  useEffect(() => {
    if (id === undefined) {
      dispatch(
        setCategoriesReq({
          parentCategoryId: undefined,
          onNull: onNullCategories,
        })
      );
    } else {
      dispatch(
        setCategoriesReq({ parentCategoryId: id, onNull: onNullCategories })
      );
    }
  }, [id]);

  const onNullCategories = () => {
    history.push(FILES_PAGE);
  };

  const changeOpenCreateCategoryModal = () => {
    setOpenCreateCategoryModal((oldState) => !oldState);
  };

  const changeOpenCreateTagModal = () => {
    setOpenCreateTagModal((oldState) => !oldState);
  };

  const getNameHandler = (name) => {
    if (matches) {
      if (name.length > 7) return name.substr(0, 7) + "...";
      return name;
    } else {
      if (name.length > 14) return name.substr(0, 14) + "...";
      return name;
    }
  };

  const onSuccessCreatingCategoryHandler = () => {
    dispatch(
      setCategoriesReq(
        id === undefined
          ? { parentCategoryId: undefined, onNull: onNullCategories }
          : { parentCategoryId: id, onNull: onNullCategories }
      )
    );
    setOpenCreateCategoryModal(false);
  };

  const onSuccessCreatingTagHandler = () => {
    setOpenCreateTagModal(false);
  };

  const createCategoryHandler = () => {
    dispatch(
      createCategoryReq({
        name: createCategoryName,
        parentId: id !== undefined ? id : null,
        onSuccess: onSuccessCreatingCategoryHandler,
      })
    );
    setCreateCategoryName("");
  };

  const createTagHandler = () => {
    dispatch(
      createTagReq({
        name: createTagName,
        onSuccess: onSuccessCreatingTagHandler,
      })
    );
    setCreateTagName("");
  };

  const ColoredLine = () => (
    <hr
      style={{
        backgroundColor: "#D8D8D8",
        height: 1,
        border: 0,
      }}
    />
  );

  return (
    <>
      <div
        className="l-t-rectangle"
        style={{ height: matches ? 140 : 180 }}
      ></div>
      <div
        className="r-b-rectangle"
        style={{ width: matches ? 100 : 180 }}
      ></div>
      <CustomModal
        classes="files-custom-modal"
        open={openCreateCategoryModal}
        onCloseModal={changeOpenCreateCategoryModal}
      >
        <div className="add-pattern-modal-header">
          <div className="add-pattern-modal-header-title">
            <div className="add-pattern-modal-header-title-image">
              <img src={plusIcon} alt="plus" />
            </div>
            <div className="add-pattern-modal-header-title-title">
              <p>{t("files.createCategoryModalTitle")}</p>
            </div>
          </div>
          <div
            className="add-pattern-modal-header-close"
            onClick={changeOpenCreateCategoryModal}
          >
            <img src={xIcon} alt="close" />
          </div>
        </div>
        <div className="add-category-input">
          <input
            type="text"
            placeholder={t("files.createCategoryModalPlaceholder")}
            value={createCategoryName}
            onChange={(e) => setCreateCategoryName(e.target.value)}
          />
        </div>
        <div className="files-category-custom-modal-buttons">
          <Button
            type="button"
            variant="contained"
            className="c-btn c-btn--primary-outlined"
            onClick={changeOpenCreateCategoryModal}
          >
            {t("files.close")}
          </Button>
          <Button
            type="button"
            variant="contained"
            className="c-btn c-btn--primary"
            onClick={createCategoryHandler}
          >
            {t("files.createCategoryModalSubmitButton")}
          </Button>
        </div>
      </CustomModal>
      <CustomModal
        classes="files-custom-modal"
        open={openCreateTagModal}
        onCloseModal={changeOpenCreateTagModal}
      >
        <div className="add-pattern-modal-header">
          <div className="add-pattern-modal-header-title">
            <div className="add-pattern-modal-header-title-image">
              <img src={plusIcon} alt="plus" />
            </div>
            <div className="add-pattern-modal-header-title-title">
              <p>{t("files.createTagModalTitle")}</p>
            </div>
          </div>
          <div
            className="add-pattern-modal-header-close"
            onClick={changeOpenCreateTagModal}
          >
            <img src={xIcon} alt="close" />
          </div>
        </div>
        <div className="add-category-input">
          <input
            type="text"
            placeholder={t("files.createTagModalPlaceholder")}
            value={createTagName}
            onChange={(e) => setCreateTagName(e.target.value)}
          />
        </div>
        <div className="files-category-custom-modal-buttons">
          <Button
            type="button"
            variant="contained"
            className="c-btn c-btn--primary-outlined"
            onClick={changeOpenCreateTagModal}
          >
            {t("files.close")}
          </Button>
          <Button
            type="button"
            variant="contained"
            className="c-btn c-btn--primary"
            onClick={createTagHandler}
          >
            {t("files.createTagModalSubmitButton")}
          </Button>
        </div>
      </CustomModal>
      <div
        className="pl-144"
        style={{ paddingTop: "36px" }}
        onClick={() => {
          setTrigger((trigger) => trigger + 1);
        }}
      >
        <div
          style={{
            marginBottom: matches ? "20px" : "50px",
            marginTop: matches && "20px",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: matches ? "flex-start" : "center",
                flexDirection: matches ? "column" : "row",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <h1 className="page-heading">{t("files.folders")}</h1>
              <div className="add-categories-files">
                <IconButton
                  className={
                    `c-btn ads-page-btn c-btn--primary filesPage ` +
                    (matches ? "files-button-responsive" : "add-ad-btn")
                  }
                  onClick={() => history.push(ADD_FILE)}
                >
                  + {t("files.file")}
                </IconButton>
                <IconButton
                  className={
                    `c-btn ads-page-btn c-btn--primary filesPage ` +
                    (matches ? "files-button-responsive" : "add-ad-btn")
                  }
                  onClick={changeOpenCreateCategoryModal}
                >
                  + {t("files.category")}
                </IconButton>
                <IconButton
                  className={
                    `c-btn ads-page-btn c-btn--primary filesPage ` +
                    (matches ? "files-button-responsive" : "add-ad-btn")
                  }
                  onClick={changeOpenCreateTagModal}
                >
                  + {t("files.tag")}
                </IconButton>
              </div>
            </div>
            <ColoredLine />
            <div className="page-navigation-buttons">
              <div
                onClick={() => history.push({ pathname: FILES_PAGE })}
                className="files-file-routing"
              >
                {t("files.root")}
              </div>
              {childParentRelations.map((relation) => (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  key={relation.id}
                >
                  <span
                    style={{
                      cursor: "auto",
                      color: "#A9A9A9",
                      fontWeight: "bold",
                      marginLeft: "10px",
                    }}
                  >
                    &gt; &nbsp;
                  </span>
                  <div
                    className="files-file-routing"
                    onClick={() => history.push({ pathname: relation.id })}
                  >
                    {getNameHandler(relation.name)}
                  </div>
                </div>
              ))}
            </div>
            <ColoredLine />
          </div>
          <div
            className={
              matches
                ? "files-page-categories-responsive"
                : "files-page-categories"
            }
          >
            {categories &&
              categories.map((category) => (
                <div
                  className={
                    matches
                      ? "files-page-categories-category-responsive"
                      : "files-page-categories-category"
                  }
                  key={category.id}
                >
                  <IconButton
                    className="c-btn c-btn--primary-outlined files-page-category-button"
                    data-testid="pattern-details-send-email"
                    onClick={() =>
                      history.push({
                        pathname: FILES_PAGE + "/" + category.id,
                      })
                    }
                  >
                    <img
                      style={{
                        marginRight: "5px",
                        width: "12px",
                        height: "12px",
                      }}
                      src={table}
                    />
                    {getNameHandler(category.name)}
                  </IconButton>
                </div>
              ))}
          </div>
        </div>

        <div>
          <div style={{ marginBottom: "0px" }}>
            <h1 className="page-heading">{t("files.files")}</h1>
          </div>
          <div className="files-page-categories">
            <FileTable trigger={trigger} />
          </div>
        </div>
      </div>
    </>
  );
};

FilesPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  setFile: PropTypes.func,
};

export default FilesPage;
