import {
  FETCH_ROOT_CATEGORIES_REQ,
  FETCH_ROOT_CATEGORIES_SUCCESS,
  FETCH_ROOT_CATEGORIES_ERR,
  FETCH_IS_CATEGORIES_CHECKED_ERR,
  FETCH_IS_CATEGORIES_CHECKED_REQ,
  FETCH_IS_CATEGORIES_CHECKED_SUCCESS,
  CHANGE_IC_CHECKED_CATEGORY,
  GRANT_CATEGORY_REQ,
  GRANT_CATEGORY_ERR,
  GRANT_CATEGORY_SUCCESS,
  FETCH_ALL_CATEGORIES_ERR,
  FETCH_ALL_CATEGORIES_REQ,
  FETCH_ALL_CATEGORIES_SUCCESS,
  CREATE_CATEGORY_REQ,
  CREATE_CATEGORY_ERR,
  CREATE_CATEGORY_SUCCESS,
} from "./categoriesActionConstants";

export const setCategoriesReq = (payload) => ({
  type: FETCH_ROOT_CATEGORIES_REQ,
  payload,
});

export const setCategoriesError = (payload) => ({
  type: FETCH_ROOT_CATEGORIES_ERR,
  payload,
});

export const setCategories = (payload) => ({
  type: FETCH_ROOT_CATEGORIES_SUCCESS,
  payload,
});

export const setIsCategoriesCheckedReq = (payload) => ({
  type: FETCH_IS_CATEGORIES_CHECKED_REQ,
  payload,
});

export const setIsCategoriesCheckedError = (payload) => ({
  type: FETCH_IS_CATEGORIES_CHECKED_ERR,
  payload,
});

export const setIsCategoriesChecked = (payload) => ({
  type: FETCH_IS_CATEGORIES_CHECKED_SUCCESS,
  payload,
});

export const changeIsCheckedCategory = (payload) => ({
  type: CHANGE_IC_CHECKED_CATEGORY,
  payload,
});

export const setGrantCategoriesReq = (payload) => ({
  type: GRANT_CATEGORY_REQ,
  payload,
});

export const setGrantCategoriesError = (payload) => ({
  type: GRANT_CATEGORY_ERR,
  payload,
});

export const setGrantCategories = (payload) => ({
  type: GRANT_CATEGORY_SUCCESS,
  payload,
});

export const setAllCategoriesReq = () => ({
  type: FETCH_ALL_CATEGORIES_REQ,
});

export const setAllCategoriesError = (payload) => ({
  type: FETCH_ALL_CATEGORIES_ERR,
  payload,
});

export const setAllCategories = (payload) => ({
  type: FETCH_ALL_CATEGORIES_SUCCESS,
  payload,
});

export const createCategoryReq = (payload) => ({
  type: CREATE_CATEGORY_REQ,
  payload,
});

export const createCategoryError = () => ({
  type: CREATE_CATEGORY_ERR
});

export const setCreateCategory = (payload) => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload,
});
