export default {
  app: {
    title: "HR Centar",
  },
  refresh: {
    //   title: 'Are you active?',
    //   cta:
    //     "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    //   close: 'Close',
    //   trademark: 'TM',
    search: "Pretraži",
    //   error: 'Error',
    //   continue: 'Continue',
    labelUsername: "Korisničko ime",
    labelPassword: "Šifra",
    labelConfirmPassword: "Ponovljena šifra",
    or: "ili",
    and: "i",
    //   next: 'Next',
    //   nextPage: 'Next page',
    //   previousPage: 'Previous page',
    back: "NAZAD",
    //   goBack: 'Go Back',
    //   ok: 'Ok',
    //   done: 'Done',
    confirm: "Potvrdi",
    //   printDownload: 'Print/Download',
    cancel: "Otkaži",
    delete: "Obriši",
    change: "Promeni",
    //   remove: 'Remove',
    //   invite: 'Invite',
    //   save: 'Save',
    //   complete: 'Complete',
    download: "Preuzmi",
    //   yes: 'Yes',
    //   no: 'No',
    to: "Do",
    from: "Od",
    //   select: 'Select...',
    //   none: 'None',
    //   date: {
    //     range: '{{start}} to {{end}}',
    //   },
    continue: "NASTAVI",
    firstName: "Ime",
    lastName: "Prezime",
    gender: "Pol",
    male: "Muški",
    female: "ženski",
    dateOfBirth: "Datum rođenja",
    phoneNumber: "Broj telefona",
    socialNetwork: "Društvene mreže",
    address: "adresa",
    specialist: "Specijalista",
    title: "Naslov",
    experience: "godina iskustva",
  },
  login: {
    welcome: "Dobrodošli!",
    //   dontHaveAccount: "Don't have an account? ",
    //   emailFormat: 'Invalid email address format.',
    //   emailRequired: 'An email or username is required.',
    //   noUsers: 'There are no users with that email.',
    //   passwordStrength: 'Your password is {{strength}}.',
    //   passwordLength: 'Your password contain between 8 and 50 characters.',
    //   signUpRecommendation: 'Sign up',
    //   email: 'Please enter your email address or username to log in:',
    logInTitle: "Prijavi se",
    logIn: "Prijavi se",
    firstnameRequired: "Potrebno je uneti ime.",
    lastnameRequired: "Potrebno je uneti prezime.",
    emailRequired: "Potrebno je uneti email adresu.",
    //   signUp: 'Sign Up',
    usernameRequired: "Potrebno je uneti korisničko ime.",
    passwordRequired: "Potrebno je uneti lozinku.",
    forgotYourPassword: "Zaboravio/la si lozinku?",
    resetYourPassword: "Nova lozinka",
    resetYourPasswordHelpText: "Unesi novu lozinku.",
    forgotYourPasswordHelpText:
      "Samo unesi e-mail adresu svog HR Center profila.",
    forgotYourPasswordButton: "POŠALJI",
    forgotYourPasswordBackLink: "Nazad na Login",
    forgotYourPasswordConfimation:
      "Proveri email adresu da bi resetovao lozinku.",
    passwordDontMatch: "Lozinke se ne poklapaju.",
    //   _useDifferentEmail: 'Use different email address or username',
    //   get useDifferentEmail() {
    //       return this._useDifferentEmail;
    //   },
    //   set useDifferentEmail(value) {
    //       this._useDifferentEmail = value;
    //   },
    signInWithGoogle: "Prijava putem Google-a",
    invalidEmail: "Format adrese nije validan",
    specialCharacterRequired:
      "Lozinka mora da sadrži najmanje jedan poseban znak",
    numberRequired: "Lozinka mora da sadrži najmanje jedan broj",
    lowercaseRequired: "Lozinka mora da sadrži najmanje jedno malo slovo",
    uppercaseRequired: "Lozinka mora da sadrži najmanje jedno veliko slovo",
    numberOfCharactersRequired: "Lozinka mora da sadrži najmanje 8 karaktera",
    retypePassword: "Molimo ponovo unesite lozinku.",
    matchPasswords: "Lozinke se ne poklapaju",
    passwordConfirmation: "Potvrda lozinke",
  },
  // password: {
  //   weak: 'weak',
  //   average: 'average',
  //   good: 'good',
  //   strong: 'strong',
  // },
  // forgotPassword: {
  //   title: 'Forgot Password',
  //   label: 'Send email',
  //   emailRequired: 'An email is required.',
  //   emailFormat: 'Invalid email address format.',
  //   forgotPassword: {
  //     title: 'Forgot Password',
  //     subtitle:
  //       'Please answer the security question to gain access to your account:',
  //     label: 'Reset Password',
  //   },
  // },
  // notFound: {
  //   text: "We're sorry but we couldn't find the page you were looking for.",
  //   goBack: 'Go back to homepage',
  // },
  // errorPage: {
  //   text:
  //     "We're sorry, an internal server error came up. Please be patient or try again later.",
  //   goBack: 'Go back to homepage',
  //   logout: 'Logout',
  // },
  // apiErrors:{
  //   ClientIpAddressIsNullOrEmpty:"Client Ip address is null or empty",
  //   UsernameDoesNotExist: "Username does not exist"
  // },

  nav: {
    navigation: "Navigacija",
    ads: "Oglasi",
    selectionFlow: "Tok Selekcije",
    candidates: "Kandidati",
    schedule: "Planer",
    patterns: "Šabloni",
    statistics: "Statistika",
    users: "Korisnici",
    signOut: "Izloguj se",
    files: "Fajlovi",
  },
  ads: {
    ads: "Oglasi",
    dragPdf1: "Prevuci dokument ili",
    dragPdf2: "na racunaru",
  },
  // selection: {
  //   title: "Tok Selekcije",
  //   subtitle: "Svi kandidati",
  //   tipHeader: "Savet za uspešan Screening test",
  //   tipBody:
  //     "Zapamtite da odeljenje za ljudske resurse u sebi sadrži reč „ljudski“. HR treba da vas vidi kakvi ste i da bi stekli osećaj za vašu stvarnu ličnost i postarali se da se dobro uklopite u kompaniju. Zato je bolje da se ponašate prirodno i opušteno. Važno je i pokazati da posedujete snažne međuljudske veštine i da se ponašate profesionalno. Na dan intervjua budite tačni, predstavite se pristojno i obucite se na odgovarajući način. To znači da razmislite o slici kompanije, ali i da se odevate na način koji vam je ugodan tokom dana.",
  //   filterStatus: "Status",
  //   filterDate: "Datum",
  //   filterFrom: "Od",
  //   filterTo: "Do",
  //   filterSubmit: "Pretraži",
  // },
  users: {
    management: "Upravljanje korisnicima",
    fullName: "Ime i prezime",
    position: "Pozicija",
    invite: "Pozovi",
    inviteUser: "Pozovi korisnika",
    regLink: "Registracioni link",
    receiver: "Primalac",
    user: "Korisnik",
    contact: "Kontakt",
    phone: "Telefon",
    socials: "Društvene mreže",
    resetPassword: "Resetuj lozinku",
    resetLink:
      "Da li ste sigurni da želite da pošaljete link za resetovanje lozinke?",
    disableUser: "Onemogući korisnika",
    questionDisableUser:
      "Da li ste sigurni da želite da onemogućite korisnika?",
    deleteUser: "Obrišite korisnika",
    questionDeleteUser: "Da li ste sigurni da želite ukloniti korisnika?",
    block: "Blokiraj",
    unblock: "Odblokiraj",
    profile: "Uredi profil",
    positionNotDeclared: "Pozicija nije još uvek određena.",
    backToUsers: "Nazad na listu korisnika",
    noSocialMedia: "Korisnik nije deo nijedne društvene mreže.",
    noPhoneNumber: "Korisnik nema sacuvan broj telefona.",
    grantedCategories: "Dodeljene kategorije",
    applyChanges: "Primenite promene",
  },
  filter: {
    title: "Filteri",
  },
  registration: {
    phone: "Broj Telefona",
    linkedinProfile: "LinkedIn profil",
    position: "Pozicija",
    link: "Registracioni link",
    register: "Registruj se",
    twoSteps: "Dva koraka do HR Centra.",
    mistake: "Došlo je do greške...",
  },
  filters: {
    filters: "Filteri",
    experience: "Godine iskustva",
    technologies: "Tehnologije",
    employmentType: "Tip zaposlenja",
    internship: "Stručna praksa",
    work: "Posao",
    workHour: "Radno vreme",
    partTime: "Skraćeno vreme",
    fullTime: "Puno vreme",
    search: "Pretraži",
    dateOfApplication: "Datum prijave",
    category: "Kategorija",
    creationDate: "Datum kreiranja",
    advancedTechnologies: "Napredne tehnologije",
    resetFilters: "Resetuj filtere",
  },
  candidates: {
    search: "Pretraga",
  },
  dialogs: {
    candidateName: "Ime kandidata",
    interviewerName: "Ime intervjuera (opciono)",
    interviewerName2: "Ime intervjuera",
    duration: "Trajanje",
    appointment: "Termin",
  },
  patterns: {
    editing2: "Režim uređivanja",
  },
  files: {
    addFile: "Dodajte Fajl",
    addFileCategories: "Kategorije",
    addFileSelectedCategory: "Izabrana kategorija",
    addFileSetRoot: "Podesite koren",
    addFileTags: "Tagovi",
    addFileDragAndDrop: "Prevucite fajl",
    addFileSuccessfullyAddedFile: "Uspešno dodat fajl",
    addFileGoBackToFiles: "Nazad na sve fajlove",
    createCategoryModalTitle: "Napravite kategoriju",
    createTagModalTitle: "Napravite tag",
    createCategoryModalPlaceholder: "Naziv kategorije",
    createTagModalPlaceholder: "Naziv taga",
    close: "Izađite",
    createCategoryModalSubmitButton: "Napravite kategoriju",
    createTagModalSubmitButton: "Napravite tag",
    folders: "Folderi",
    file: "Fajl",
    files: "Fajlovi",
    category: "Kategorija",
    root: "Koren",
    confirmDialogTitle: "Brisanje dokumenta",
    confirmDialogContent: "Da li ste sigurni za brisanje dokumenta?",
    editText: "Izmena teksta",
    editTextSub: " | Beleška",
    saveChanges: "Sačuvajte izmene",
    doucmentPath: "Putanja dokumenta",
    doucmentName: "Naziv dokumenta",
    doucmentType: "Tip dokumenta",
    doucmentSize: "Veličina dokumenta",
    deleteDocument: "Obrišite dokument",
    note: "Beleška",
    downloadDocument: "Preuzmite dokument",
    filters: "Filteri",
    documents: "| Dokumenti",
    extension: "Ekstenzija",
    tags: "Tagovi",
    search: "Pretraži",
    tag: "Tag",
  },
};
