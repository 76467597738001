import {
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERR,
} from "../../actions/uploadFile/uploadFileActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  file: null,
  errorMessage: "",
};

export default createReducer(
  {
    [UPLOAD_FILE_SUCCESS]: setStateFile,
    [UPLOAD_FILE_ERR]: setFileErrorMessage,
  },
  initialState
);

function setStateFile(state, action) {
  return {
    ...state,
    file: action.payload,
  };
}

function setFileErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
