import { getRequest } from ".";
import apiEndpoints from "./apiEndpoints";

export const getAllFilesReq = (payload) => {
  let tagQuery = "";
  for (let i = 0; i < payload.tags.length; i++) {
    tagQuery += `&tags=${payload.tags[i]}&`;
  }

  let extQuery = "";
  for (let i = 0; i < payload.extensions.length; i++) {
    extQuery += `&extensions=${payload.extensions[i]}`;
  }

  return getRequest(
    apiEndpoints.files.all +
      "?currentPage=" +
      payload.currentPage +
      "&pageSize=" +
      payload.pageSize +
      extQuery +
      tagQuery +
      (payload.categoryId === undefined
        ? ""
        : "&categoryId=" + payload.categoryId) +
      (payload.content === "" ? "" : "&content=" + payload.content)
  );
};
