import {
  SET_USER,
  SET_USER_ERROR,
  RESET_USER_STATE
} from './userActionConstants';

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setUserError = (payload) => ({
  type: SET_USER_ERROR,
  payload,
});

export const resetUserState = () => ({
  type:RESET_USER_STATE
})
