import createReducer from "../../utils/createReducer";
import {
  FETCH_FILES_ERR,
  FETCH_FILES_SUCCESS,
} from "../../actions/files/fileActionConstants";
import {
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_ERR,
} from "../../actions/files/fileActionConstants";
import {
  DELETE_FILE_ERR,
  DELETE_FILE_SUCCESS,
} from "../../actions/files/fileActionConstants";

const initialState = {
  data: {},
  fetchFilesErrorMessage: "",
};

export default createReducer(
  {
    [FETCH_FILES_SUCCESS]: setFiles,
    [FETCH_FILES_ERR]: setFilesErrorMessage,
    [UPDATE_FILE_SUCCESS]: updateFileReducer,
    [UPDATE_FILE_ERR]: updateFileError,
    [DELETE_FILE_SUCCESS]: deleteFileReducer,
    [DELETE_FILE_ERR]: deleteFileReducerError,
  },
  initialState
);

function setFiles(state, action) {
  return {
    ...state,
    data: action.payload,
  };
}

function setFilesErrorMessage(state, action) {
  return {
    ...state,
    fetchFilesErrorMessage: action.payload,
  };
}

function updateFileReducer(state, action) {
  const newData = state.data.data.map((dat) =>
    dat.stream_id === action.payload.id
      ? { ...dat, note: action.payload.data }
      : dat
  );

  return {
    ...state,
    data: { ...state.data, data: newData },
  };
}

function updateFileError(state, action) {
  return {
    ...state,
    fetchFilesErrorMessage: action.payload,
  };
}

function deleteFileReducer(state, action) {
  const newArr = state.data.data.filter((x) => x.stream_id !== action.payload);
  return { ...state, data: { ...state.data, data: newArr } };
}

function deleteFileReducerError(state, action) {
  return {
    ...state,
    fetchFilesErrorMessage: action.payload,
  };
}
