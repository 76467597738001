import createReducer from "../../utils/createReducer";
import {
  USER_INVITE_ERR,
  USER_INVITE_SUCCESS,
} from "../../actions/users/usersActionConstants";
// import { setUsersError } from "../../actions/users/usersActions";

const initialState = {
  isSuccess: false,
  errorMessage: "",
};

export default createReducer(
  {
    [USER_INVITE_SUCCESS]: inviteSucceded,
    [USER_INVITE_ERR]: inviteFailed,
  },
  initialState
);

function inviteSucceded(state) {
  return {
    ...state,
    isSuccess: !state.isSuccess,
  };
}

function inviteFailed(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
