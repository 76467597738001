import {
  createFetchType,
  createSuccessType,
  createErrorType,
} from "../actionHelpers";

const FETCH_TAGS_SCOPE = "FETCH_TAGS";
const CREATE_TAG_SCOPE = "CREATE_TAG";

export const FETCH_TAGS_REQ = createFetchType(FETCH_TAGS_SCOPE);
export const FETCH_TAGS_ERR = createErrorType(FETCH_TAGS_SCOPE);
export const FETCH_TAGS_SUCCESS = createSuccessType(FETCH_TAGS_SCOPE);

export const CHANGE_TAG_ISCHECKED_VALUE = "CHANGE_TAG_ISCHECKED_VALUE";
export const RESET_CHECKED_TAGS = "RESET_CHECKED_TAGS";

export const CREATE_TAG_REQ = createFetchType(CREATE_TAG_SCOPE);
export const CREATE_TAG_ERR = createErrorType(CREATE_TAG_SCOPE);
export const CREATE_TAG_SUCCESS = createSuccessType(CREATE_TAG_SCOPE);
