import { useFormik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  // Typography,
} from "@mui/material";
import Button from "../Button/Button";
import { useState } from "react";
// import googleLogo from "../../assets/images/google1.png";
import DiligLogo from "../../assets/images/logo_horizontal_black.png";
import { Box } from "@mui/system";
import { FormContext } from "../../context/FormContext";
import { useLocation } from "react-router-dom";

function FirstStepForm() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  const { search } = useLocation();

  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const submitHandler = (values) => {
    const data = {
      ...formData,
      ...values,
      token: search.split("&")[0].split("=")[1],
    };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };

  const formik = useFormik({
    initialValues: {
      email: search.split("&")[1].split("=")[1],
      password: "",
      confirm: "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required(t("login.emailRequired"))
        .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, t("login.invalidEmail")),
      password: yup
        .string()
        .required(t("login.passwordRequired"))
        .matches(
          // eslint-disable-next-line
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
          t("login.specialCharacterRequired")
        )
        .matches(/[0-9]/g, t("login.numberRequired"))
        .matches(/[a-z]/g, t("login.lowercaseRequired"))
        .matches(/[A-Z]/g, t("login.uppercaseRequired"))
        .min(8, t("login.numberOfCharactersRequired")),
      confirm: yup
        .string()
        .required(t("login.retypePassword"))
        .oneOf([yup.ref("password")], t("login.matchPasswords")),
    }),
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Box
      onSubmit={formik.handleSubmit}
      component="form"
      sx={{ position: "relative" }}
    >
      {/* <Backdrop
          position="absolute"
          isLoading={isLoading || isGoogleLoading}
        /> */}
      <>
        <TextField
          name="email"
          // label={"E-mail"}
          margin="normal"
          disabled
          fullWidth
          inputProps={{ "data-testid": "reg-input-1" }}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          className="rounded-input"
          name="password"
          label={t("common.labelPassword")}
          margin="normal"
          type={showPassword ? "text" : "password"}
          fullWidth
          inputProps={{ "data-testid": "reg-input-2" }}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          className="rounded-input"
          name="confirm"
          label={t("login.passwordConfirmation")}
          margin="normal"
          type={showPassword ? "text" : "password"}
          fullWidth
          inputProps={{ "data-testid": "reg-input-3" }}
          onChange={formik.handleChange}
          error={formik.touched.confirm && Boolean(formik.errors.confirm)}
          helperText={formik.touched.confirm && formik.errors.confirm}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </>
      <Button
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
        fullWidth
        className="c-btn c-btn--primary w-289 f"
        type="submit"
      >
        {t("common.continue")}
      </Button>
      {/* <div className="flex-center">
        <div className="hr hr-s"></div>
        <div className="hr-mid">{t("common.or")}</div>
        <div className="hr hr-e"></div>
      </div>
      <div>
        <Button
          className="c-btn c-btn--gray flex-center w-289"
          sx={{ width: "100%", mt: 2 }}
          // onClick={handleGoogleSubmit}
        >
          <img src={googleLogo} style={{ marginRight: "15px" }} />
          <Typography sx={{ m: 0, p: 0 }} variant="buttonText">
            {t("login.signInWithGoogle")}
          </Typography>
        </Button>
        <div id="signInDiv"></div>
      </div> */}
      <div className="flex-center">
        <img src={DiligLogo} style={{ margin: "50px auto 30px auto" }} />
      </div>
    </Box>
  );
}

export default FirstStepForm;
