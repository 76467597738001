import {
  FETCH_TAGS_REQ,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_ERR,
  CHANGE_TAG_ISCHECKED_VALUE,
  RESET_CHECKED_TAGS,
  CREATE_TAG_ERR,
  CREATE_TAG_REQ,
  CREATE_TAG_SUCCESS,
} from "./tagsActionConstants";

export const setTagsReq = () => ({
  type: FETCH_TAGS_REQ,
});

export const setTagsError = (payload) => ({
  type: FETCH_TAGS_ERR,
  payload,
});

export const setTags = (payload) => ({
  type: FETCH_TAGS_SUCCESS,
  payload,
});

export const changeTagIsCheckedValue = (payload) => ({
  type: CHANGE_TAG_ISCHECKED_VALUE,
  payload,
});

export const resetIsCheckedTagsValue = () => ({
  type: RESET_CHECKED_TAGS,
});

export const createTagReq = (payload) => ({
  type: CREATE_TAG_REQ,
  payload,
});

export const createTagError = () => ({
  type: CREATE_TAG_ERR,
});

export const setCreateTag = (payload) => ({
  type: CREATE_TAG_SUCCESS,
  payload,
});
