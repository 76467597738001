/* istanbul ignore file */
export const BASE_PAGE = '/';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const HOME_PAGE = '/home';
export const ERROR_PAGE = '/error-page';
export const NOT_FOUND_PAGE = '/not-found';
export const USERS_PAGE = '/users';
export const USER_DETAILS_PAGE = '/users/:id';
export const FORGOT_PASSWORD_CONFIRMATION_PAGE = '/forgot-password-confirmation';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const REGISTER_PAGE = '/register';
export const FILES_PAGE = '/files';
export const FILES_DEPTH_PAGE = '/files/:id';
export const ADD_FILE = '/add-file';
