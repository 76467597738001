import { deleteRequest, getRequest, postRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const getAllUsers = () => getRequest(apiEndpoints.users.allUsers);
export const inviteUserRequest = (payload) => postRequest(apiEndpoints.users.invite, payload)

export const enableUserRequest = (id) =>
  postRequest(
    replaceInUrl(apiEndpoints.users.toggleEnabled, {
      id,
    })
  );

export const deleteUserRequest = (id) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.users.user, {
      id,
    })
  );

export const userDetailsRequest = (id) =>
  getRequest(
    replaceInUrl(apiEndpoints.users.user, {
      id,
    })
  );
