import {
  CHANGE_IC_CHECKED_CATEGORY,
  FETCH_ROOT_CATEGORIES_ERR,
  FETCH_ROOT_CATEGORIES_SUCCESS,
  FETCH_IS_CATEGORIES_CHECKED_ERR,
  FETCH_IS_CATEGORIES_CHECKED_SUCCESS,
  GRANT_CATEGORY_ERR,
  GRANT_CATEGORY_SUCCESS,
  FETCH_ALL_CATEGORIES_ERR,
  FETCH_ALL_CATEGORIES_SUCCESS,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERR,
} from "../../actions/categories/categoriesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  categories: [],
  childParentRelations: [],
  allCategories: [],
  isCategoriesChecked: [],
  changedCategories: [],
  errorMessage: "",
};

export default createReducer(
  {
    [FETCH_ROOT_CATEGORIES_SUCCESS]: setStateCategories,
    [FETCH_ROOT_CATEGORIES_ERR]: setCategoriesErrorMessage,
    [FETCH_IS_CATEGORIES_CHECKED_SUCCESS]: setStateIsCategoriesChecked,
    [FETCH_IS_CATEGORIES_CHECKED_ERR]: setIsCategoriesCheckedErrorMessage,
    [CHANGE_IC_CHECKED_CATEGORY]: setIsCheckedCategory,
    [GRANT_CATEGORY_SUCCESS]: setStateGrantCategories,
    [GRANT_CATEGORY_ERR]: setGrantCategoriesErrorMessage,
    [FETCH_ALL_CATEGORIES_SUCCESS]: setStateAllCategories,
    [FETCH_ALL_CATEGORIES_ERR]: setStateAllCategoriesErrorMessage,
    [CREATE_CATEGORY_SUCCESS]: setCreateCategoryReducer,
    [CREATE_CATEGORY_ERR]: setCreateCategoryErrorMessage,
  },
  initialState
);

function setStateCategories(state, action) {
  return {
    ...state,
    categories: action.payload.categories,
    childParentRelations: action.payload.childParentRelations,
  };
}

function setCategoriesErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setStateIsCategoriesChecked(state, action) {
  return {
    ...state,
    isCategoriesChecked: action.payload,
  };
}

function setIsCategoriesCheckedErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setIsCheckedCategory(state, action) {
  const tmpChangedCategoriesIndex = state.changedCategories.findIndex(
    (x) => x.id === action.payload.id
  );

  var tmpChangedCategories = [...state.changedCategories];
  if (tmpChangedCategoriesIndex === -1) {
    tmpChangedCategories = [
      ...tmpChangedCategories,
      { id: action.payload.id, isChecked: !action.payload.isChecked },
    ];
  } else {
    tmpChangedCategories.splice(tmpChangedCategoriesIndex, 1);
  }

  const tmpIndex = state.isCategoriesChecked.findIndex(
    (x) => x.id === action.payload.id
  );

  if (tmpIndex === -1) {
    return state;
  }

  return {
    ...state,
    isCategoriesChecked: state.isCategoriesChecked.map((category, index) =>
      tmpIndex === index
        ? { ...category, isChecked: !category.isChecked }
        : category
    ),
    changedCategories: tmpChangedCategories,
  };
}

function setStateGrantCategories(state) {
  return {
    ...state,
    changedCategories: [],
  };
}

function setGrantCategoriesErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setStateAllCategories(state, action) {
  return {
    ...state,
    allCategories: action.payload,
  };
}

function setStateAllCategoriesErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setCreateCategoryReducer(state) {
  return {
    ...state,
  };
}

function setCreateCategoryErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
