import {
  DELETE_USER_ERR,
  DELETE_USER_REQ,
  DELETE_USER_SUCCESS,
  FETCH_USERS_ERR,
  FETCH_USERS_REQ,
  FETCH_USERS_SUCCESS,
  TOGGLE_USER_ENABLE_ERR,
  TOGGLE_USER_ENABLE_REQ,
  TOGGLE_USER_ENABLE_SUCCESS,
  USER_DETAILS_ERR,
  USER_DETAILS_REQ,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_TOGGLE,
  USER_INVITE_ERR,
  USER_INVITE_REQ,
  USER_INVITE_SUCCESS,
} from "./usersActionConstants";

// USER LIST
export const setUsersReq = () => ({
  type: FETCH_USERS_REQ,
});

export const setUsersError = (payload) => ({
  type: FETCH_USERS_ERR,
  payload,
});

export const setUsers = (payload) => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

// TOGGLE ENABLE TO LOGIN
export const setEnableUsersReq = (payload) => ({
  type: TOGGLE_USER_ENABLE_REQ,
  payload,
});

export const setEnableUsersError = (payload) => ({
  type: TOGGLE_USER_ENABLE_ERR,
  payload,
});

export const setEnableUsers = (payload) => ({
  type: TOGGLE_USER_ENABLE_SUCCESS,
  payload,
});

// DELETE USER
export const deleteUserReq = (payload) => ({
  type: DELETE_USER_REQ,
  payload,
});

export const deleteUserError = (payload) => ({
  type: DELETE_USER_ERR,
  payload,
});

export const deleteStateUser = (payload) => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

// SINGLE USER DETAILS
export const userDetailsReq = (payload) => ({
  type: USER_DETAILS_REQ,
  payload,
});

export const userDetailsError = (payload) => ({
  type: USER_DETAILS_ERR,
  payload,
});

export const stateUserDetailsSuccess = (payload) => ({
  type: USER_DETAILS_SUCCESS,
  payload,
});

export const toggleSingleUser = () => ({
  type: USER_DETAILS_TOGGLE,
});

// INVITE USER
export const inviteUserReq = (payload) => ({
  type: USER_INVITE_REQ,
  payload,
});

export const inviteUserError = (payload) => ({
  type: USER_INVITE_ERR,
  payload,
});

export const inviteUserSuccess = () => ({
  type: USER_INVITE_SUCCESS
});
