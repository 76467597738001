import React from 'react';
import PropTypes from "prop-types";
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import HrLogo from "../../assets/images/hrcenter.png";
import DiligLogo from "../../assets/images/logo_horizontal_black.png";
import i18next from 'i18next';
import {
	Box,
	Container,
	Typography,
	Button,
	TextField,
	Link,
	Grid,
} from '@mui/material';
import Backdrop from '../../components/MUI/BackdropComponent';
import { FORGOT_PASSWORD_CONFIRMATION_PAGE, BASE_PAGE } from '../../constants/pages';
import { NavLink } from 'react-router-dom';
import { forgetPassword } from '../../store/actions/login/loginActions';


const forgotPasswordValidationSchema = Yup.object().shape({
	email: Yup.string()
		.required(i18next.t('forgotPassword.emailRequired'))
		.email(i18next.t('forgotPassword.emailFormat')),
});

const ForgotPasswordPage = ({ history }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleSubmit = (values) => {
		const email = values.email;
		dispatch(
			forgetPassword({
				email,
				handleApiResponseSuccess,
			})
		);
	};

	const handleApiResponseSuccess = () => {
		history.push({
			pathname: FORGOT_PASSWORD_CONFIRMATION_PAGE,
			state: {
				from: history.location.pathname,
			},
		});
	};

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: forgotPasswordValidationSchema,
		onSubmit: handleSubmit,
		validateOnBlur: true,
		enableReinitialize: true,
	});

	return (
		<Container
			component="main"
			maxWidth="xl"
			className="c-login-container"
			fullwidth="true">
			<div className="l-t-rectangle"></div>
			<div className="r-b-rectangle"></div>
			<Box
				sx={{
					marginTop: 2,
					width: 350,
					height: 684,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<img src={HrLogo} className="login-logo" />
				<Typography variant="h5" sx={{ m: 2, mt: 3 }}>
					{t("login.forgotYourPassword")}
				</Typography>
				<Typography variant="p">
					{t("login.forgotYourPasswordHelpText")}
				</Typography>
				<Box
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{ position: 'relative', mt: 1, p: 1 }}
				>
					<Backdrop position="absolute" isLoading={false} />
					<TextField
						name="email"
						label={t('login.forgotPasswordEmail')}
						margin="normal"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
						autoFocus
						fullWidth
						inputProps={{"data-testid": "email-input"}}
					/>
					<Button
						type="submit"
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
						fullWidth
						className="c-btn c-btn--primary"
					>
						{t('login.forgotYourPasswordButton')}
					</Button>
					<Grid container justifyContent="center">
						<Link
							to={BASE_PAGE}
							component={NavLink}
							variant="body2"
							underline="hover"
							data-testid="back-link"
						>
							{t('login.forgotYourPasswordBackLink')}
						</Link>
					</Grid>
					<div className="flex-center" data-testid="dilig-logo">
						<img src={DiligLogo} style={{ margin: "70px auto 0px auto" }} />
					</div>
				</Box>
			</Box>
		</Container>
	);
};

ForgotPasswordPage.propTypes = {
	history: PropTypes.shape({
		replace: PropTypes.func,
		push: PropTypes.func,
		location: PropTypes.shape({
			pathname: PropTypes.string,
		}),
	}),
}

export default ForgotPasswordPage;
