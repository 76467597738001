import { getRequest, postRequest } from ".";
import apiEndpoints from "./apiEndpoints";

export const getRootCategories = () =>
  getRequest(apiEndpoints.categories.rootCategories);

export const getRootCategories2 = (parentCategoryId) =>
  getRequest(apiEndpoints.categories.rootCategories + "/" + parentCategoryId);

export const getIsCategoriesChecked = (userId) =>
  getRequest(apiEndpoints.categories.isCategoriesChecked + "?userId=" + userId);

export const grantCategoryRequest = (payload) =>
  postRequest(apiEndpoints.categories.grantCategory, payload);

export const getAllCategories = () =>
  getRequest(apiEndpoints.categories.allCategories);

export const createCategoryRequest = (payload) =>
  postRequest(apiEndpoints.categories.createCategory, payload);
