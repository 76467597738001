import createReducer from "../../utils/createReducer";
import {
  REGISTER_ERROR,
  REGISTER_SUCCESS,
} from "../../actions/register/registerActionConstants";

const initialState = {
  isSuccess: false,
  errorMessage: "",
};

export default createReducer(
  {
    [REGISTER_SUCCESS]: setSuccess,
    [REGISTER_ERROR]: setError,
  },
  initialState
);

function setSuccess(state) {
  return {
    ...state,
    isSuccess: true,
  };
}

function setError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
