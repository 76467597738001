import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DiligLogo from "../../assets/images/logo_horizontal_black.png";
import * as Yup from "yup";
// import i18next from 'i18next';
import HrLogo from "../../assets/images/hrcenter.png";

import {
  Box,
  Container,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  TextField,
  Link,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Backdrop from "../../components/MUI/BackdropComponent";
import { BASE_PAGE } from "../../constants/pages";
import { NavLink } from "react-router-dom";
import { resetPassword } from "../../store/actions/login/loginActions";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
const ResetPasswordPage = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const resetPasswordValidationSchema = Yup.object().shape({
    password: Yup.string().required(t("login.passwordRequired")),
    confirmPassword: Yup.string()
      .required(t("login.passwordRequired"))
      .oneOf([Yup.ref("password"), null], t("login.passwordDontMatch")),
  });

  const handleSubmit = (values) => {
    const password = values.password;
    // const confirmPassword = values.confirmPassword;
    // if (password === confirmPassword)
    {
      const code = getQueryVariable("token"),
        email = getQueryVariable("email");
      dispatch(
        resetPassword({
          code,
          email,
          password,
          handleApiResponseSuccess,
        })
      );
    }
  };

  const handleApiResponseSuccess = () => {
    history.push({
      pathname: BASE_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Container
      component="main"
      maxWidth="xl"
      className="c-login-container"
      fullwidth="true"
    >
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <Box
        sx={{
          marginTop: 2,
          width: 350,
          height: 684,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={HrLogo} className="login-logo" />
        <Typography variant="h5" sx={{ m: 2, mt: 3 }}>
          {t("login.resetYourPassword")}
        </Typography>
        <Typography variant="p">
          {t("login.resetYourPasswordHelpText")}
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ position: "relative", mt: 1, p: 1 }}
        >
          <Backdrop position="absolute" isLoading={false} />
          <TextField
            className="rounded-input"
            name="password"
            label={t("common.labelPassword")}
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            fullWidth
            inputProps={{ "data-testid": "password-input" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="rounded-input"
            name="confirmPassword"
            label={t("common.labelConfirmPassword")}
            margin="normal"
            type={showConfirmPassword ? "text" : "password"}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            fullWidth
            inputProps={{ "data-testid": "confirm-password-input" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            fullWidth
            className="c-btn c-btn--primary"
          >
            {t("login.forgotYourPasswordButton")}
          </Button>
          <Grid container justifyContent="center">
            <Link
              to={BASE_PAGE}
              component={NavLink}
              variant="body2"
              underline="hover"
              data-testid="back-link"
            >
              {t("login.forgotYourPasswordBackLink")}
            </Link>
          </Grid>
          <div className="flex-center" data-testid="dilig-logo">
            <img src={DiligLogo} style={{ margin: "70px auto 0px auto" }} />
          </div>
        </Box>
      </Box>
    </Container>
  );
};

ResetPasswordPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default ResetPasswordPage;
