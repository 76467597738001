import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import loadingReducer from "./loading/loadingReducer";
import userReducer from "./user/userReducer";
import usersReducer from "./user/usersReducer";
import userDetailsReducer from "./user/userDetailsReducer";
import inviteUserReducer from "./user/inviteUserReducer";
import registerReducer from "./register/registerReducer";
import uploadFileReducer from "./file/uploadFileReducer";
import categoriesReducer from "./category/categoriesReducer";
import tagsReducer from "./tag/tagsReducer";
import fileFiltersReducer from "./files/fileFiltersReducer";
import getFilesReducer from "./files/getFilesReducer";

export default combineReducers({
  login: loginReducer,
  user: userReducer,
  loading: loadingReducer,
  users: usersReducer,
  userDetails: userDetailsReducer,
  invite: inviteUserReducer,
  register: registerReducer,
  uploadFile: uploadFileReducer,
  categories: categoriesReducer,
  tags: tagsReducer,
  fileFilters: fileFiltersReducer,
  files: getFilesReducer,
});
