import { useFormik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import Button from "../Button/Button";
// import googleLogo from "../../assets/images/google1.png";
import DiligLogo from "../../assets/images/logo_horizontal_black.png";
import { Box } from "@mui/system";
import { FormContext } from "../../context/FormContext";
import { useDispatch } from "react-redux";
import { registerRequest } from "../../store/actions/register/registerActions";
import { BASE_PAGE } from "../../constants/pages";
import { useHistory } from "react-router-dom";

function SecondStepForm() {
  const { formData } = useContext(FormContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const submitHandler = (values) => {
    const data = { ...formData, ...values };
    dispatch(
      registerRequest({
        model: data,
        handleApiResponseSuccess,
      })
    );
  };

  const handleApiResponseSuccess = () => {
    history.push({
      pathname: BASE_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      position: "",
      linkedin: "",
    },
    validationSchema: yup.object().shape({
      phone: yup.string().required("Required"),
      position: yup.string().required("Required"),
      linkedin: yup.string().required("Required"),
    }),
    onSubmit: submitHandler,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Box
      onSubmit={formik.handleSubmit}
      component="form"
      sx={{ position: "relative" }}
    >
      {/* <Backdrop
          position="absolute"
          isLoading={isLoading || isGoogleLoading}
        /> */}
      <>
        <TextField
          name="phone"
          label={t("registration.phone")}
          margin="normal"
          fullWidth
          inputProps={{ "data-testid": "reg-input-4" }}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        <TextField
          name="position"
          inputProps={{ "data-testid": "reg-input-5" }}
          label={t("registration.position")}
          margin="normal"
          fullWidth
          onChange={formik.handleChange}
          error={formik.touched.position && Boolean(formik.errors.position)}
          helperText={formik.touched.position && formik.errors.position}
        />
        <TextField
          name="linkedin"
          inputProps={{ "data-testid": "reg-input-6" }}
          label={t("registration.linkedinProfile")}
          margin="normal"
          fullWidth
          onChange={formik.handleChange}
          error={formik.touched.linkedin && Boolean(formik.errors.linkedin)}
          helperText={formik.touched.linkedin && formik.errors.linkedin}
        />
      </>
      <Button
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        fullWidth
        className="c-btn c-btn--primary w-289 f"
        type="submit"
      >
        {t("registration.register")}
      </Button>
      {/* <div className="flex-center">
        <div className="hr hr-s"></div>
        <div className="hr-mid">{t("common.or")}</div>
        <div className="hr hr-e"></div>
      </div>
      <div>
        <Button
          className="c-btn c-btn--gray flex-center w-289"
          sx={{ width: "100%", mt: 2 }}
          // onClick={handleGoogleSubmit}
        >
          <img src={googleLogo} style={{ marginRight: "15px" }} />
          <Typography sx={{ m: 0, p: 0 }} variant="buttonText">
            {t("login.signInWithGoogle")}
          </Typography>
        </Button>
        <div id="signInDiv"></div>
      </div> */}
      <div className="flex-center">
        <img src={DiligLogo} style={{ margin: "50px auto 30px auto" }} />
      </div>
    </Box>
  );
}

export default SecondStepForm;
