import createReducer from '../../utils/createReducer';
import { APP_LOADING } from '../../actions/app/appActionConstants';
import { RESET_LOGIN_STATE } from '../../actions/login/loginActionConstants';

const initialState = {
  [APP_LOADING]: true,
};
export default createReducer(
  {
    UPDATE_LOADER: updateLoader,
    [APP_LOADING]: updateAppReadyLoader,
    [RESET_LOGIN_STATE]: setAppNotReady,
  },
  initialState,
);

function updateLoader(state, action) {
  return {
    ...state,
    [action.payload.actionType]: action.payload.isLoading,
  };
}

function updateAppReadyLoader(state) {
  return {
    ...state,
    [APP_LOADING]: false,
  };
}

function setAppNotReady(state) {
  return {
    ...state,
    [APP_LOADING]: true,
  };
}
