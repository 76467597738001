import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {
  const [activeProcess, setActiveProcess] = useState(null);
  const [activeProcessUnsuccess, setActiveProcessUnsuccess] = useState(null);
  const [activeInterview, setActiveInterview] = useState(null);

  return (
    <SelectionContext.Provider
      value={{
        activeProcess,
        setActiveProcess,
        activeInterview,
        setActiveInterview,
        setActiveProcessUnsuccess,
        activeProcessUnsuccess,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

SelectionProvider.propTypes = {
  children: PropTypes.node,
};
