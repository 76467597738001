import { IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import filters from "../../assets/images/filters.png";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterButton = ({ onShowFilters }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <IconButton
      className={
        "c-btn--primary-outlined ads-page-btn fltr-btn c-btn userPageBtn ml-20px no-padding custom-filter-button"
      }
      onClick={onShowFilters}
    >
      {!matches && t("filters.filters")}
      <img
        style={{
          position: "relative",
          top: -0.25,
          paddingLeft: matches ? "0px" : "10px",
        }}
        src={filters}
      />
    </IconButton>
  );
};

FilterButton.propTypes = {
  onShowFilters: PropTypes.func,
};

export default FilterButton;
