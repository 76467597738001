import React, { useState, useEffect } from "react";
import IconButton from "../../components/IconButton/IconButton";
import deleteIcon from "../../assets/images/delete.png";
import editIcon from "../../assets/images/edit.png";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteFileReq,
  getFileFiltersReq,
  getFilesReq,
  setContent,
  updateFileReq,
  resetFilters,
} from "../../store/actions/files/fileActions";
import { Fade, Pagination } from "@mui/material";
import FilterButton from "../../components/Button/FilterButton";
import searchImage from "../../assets/images/search.png";
import DocsFilters from "../../components/Docs/DocsFilters";
import ConfirmDialog from "../../components/MUI/ConfirmDialog";
import FileViewer from "react-file-viewer";
import CustomModal from "../../components/UI/CustomModal";
import xIcon from "../../assets/images/x.png";
import Button from "../../components/Button/Button";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { FETCH_FILES_LOADING } from "../../store/actions/files/fileActionConstants";
import { useParams } from "react-router";
import { PAGE_SIZE_FILES } from "../../constants/keyCodeConstants";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import NumericLabel from "react-pretty-numbers";

const FileTable = ({ trigger }) => {
  const [file, setFile] = useState(null);
  const { data } = useSelector((s) => s.files);
  const { filters } = useSelector((s) => s.fileFilters);
  const [page, setPage] = useState(1);
  const [toggleFiltersDrawer, setToggleFiltersDrawer] = useState(false);
  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);
  let { id } = useParams();
  const [fileForDelete, setFileForDelete] = useState({
    open: false,
    title: "",
    streamId: null,
  });
  const [openNoteModal, setOpenNoteModal] = useState({
    open: false,
    note: null,
    streamId: "",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("370"));

  useEffect(() => {
    if (data !== null && data.data !== undefined) {
      if (data.data.length === 0) {
        setPage(getTotalNumberOfPages(data) - 1);
      }
    }
  }, [data]);

  const getTotalNumberOfPages = (data) => {
    return parseInt(data.total) <= PAGE_SIZE_FILES
      ? 1
      : Math.ceil(parseInt(data.total) / PAGE_SIZE_FILES);
  };

  useEffect(() => {
    setFile(null);
  }, [id]);

  useEffect(() => {
    if (trigger) {
      handleChangeVisibility();
    }
  }, [trigger]);

  const handleToggleFiltersDrawer = () => {
    setToggleFiltersDrawer((oldState) => !oldState);
  };

  useEffect(() => {
    handleFilters(page);
  }, [page]);

  useEffect(() => {
    dispatch(getFileFiltersReq());
    dispatch(
      getFilesReq({
        payload: {
          pageSize: PAGE_SIZE_FILES,
          currentPage: page,
          categoryId: id,
          extensions: [],
          tags: [],
          content: "",
        },
      })
    );
    setPage(1);
  }, [id]);

  const handleChange = (_, value) => {
    setFile(null);
    handleFilters(value);
    setPage(value);
  };

  const handleFilters = (value) => {
    var extFilters = [];
    filters.extensions
      ?.filter((n) => n.isChecked)
      .forEach((m) => extFilters.push(m.name));

    var tagFilters = [];
    filters.tags
      ?.filter((n) => n.isChecked)
      .forEach((m) => tagFilters.push(m.name));

    dispatch(
      getFilesReq({
        payload: {
          pageSize: PAGE_SIZE_FILES,
          currentPage: value,
          categoryId: id,
          extensions: extFilters,
          tags: tagFilters,
          content: filters.content === undefined ? "" : filters.content,
        },
      })
    );
  };

  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_FILES_LOADING)
  );

  const handleChangeVisibility = () => {
    setIsSearchFieldVisible(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleChangeContent = (value) => {
    dispatch(setContent(value));
  };

  const deleteFileHandler = (stream_id) => {
    dispatch(deleteFileReq({ id: stream_id }));
    if (file !== null && stream_id === file.streamId) {
      setFile(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      var extFilters = [];
      filters.extensions
        ?.filter((n) => n.isChecked)
        .forEach((m) => extFilters.push(m.name));

      var tagFilters = [];
      filters.tags
        ?.filter((n) => n.isChecked)
        .forEach((m) => tagFilters.push(m.name));

      dispatch(
        getFilesReq({
          payload: {
            pageSize: PAGE_SIZE_FILES,
            currentPage: page,
            categoryId: id,
            extensions: extFilters,
            tags: tagFilters,
            content: filters.content,
          },
        })
      );
      setPage(1);
      setFile(null);
    }
  };

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  const displayFile = (fileStream, streamId, fileType) => {
    setFile({ fileStream, streamId, fileType });
    console.log("Matches je", matches);
  };

  const getPathForFile = (fileType, fileStream) => {
    if (fileType === "docx") {
      return `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${fileStream}`;
    } else if (fileType === "doc") {
      return `data:application/msword;base64,${fileStream}`;
    } else {
      return `data:application/pdf;base64,${fileStream}`;
    }
  };

  const updateFileNoteHandler = () => {
    dispatch(
      updateFileReq({
        id: openNoteModal.streamId,
        data: openNoteModal.note,
        onSuccessOpenNoteModal,
      })
    );
  };

  const onSuccessOpenNoteModal = () => {
    setOpenNoteModal({
      open: false,
      note: null,
      streamId: "",
    });
  };

  const resFilters = () => {
    dispatch(resetFilters());
    dispatch(
      getFilesReq({
        payload: {
          pageSize: PAGE_SIZE_FILES,
          currentPage: page,
          categoryId: id,
          extensions: [],
          tags: [],
          content: "",
        },
      })
    );
    setFile(null)
  };

  const input = (
    <div>
      <input
        placeholder="Pretrazi..."
        value={filters.content}
        onChange={(e) => handleChangeContent(e.target.value)}
        onKeyDown={handleKeyDown}
        className="candidate-search-field"
        style={{ width: matches ? "160px" : "816px" }}
        onClick={stopPropagation}
        role="input"
      />
    </div>
  );

  return isLoading ? (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="loader-container h-withHeader">
        <span>Loading</span>
      </div>
    </div>
  ) : (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ConfirmDialog
        open={fileForDelete.open}
        title={t("files.confirmDialogTitle")}
        subtitle={fileForDelete.title}
        imgSrc={deleteIcon}
        content={t("files.confirmDialogContent")}
        onClose={() => {
          setFileForDelete({ open: false, title: "", streamId: null });
        }}
        onConfirm={() => {
          deleteFileHandler(fileForDelete.streamId);
          setFileForDelete({ open: false, title: "", streamId: null });
        }}
      />
      <CustomModal
        classes="files-viewer-custom-modal"
        open={file && matches}
        onCloseModal={() => setFile(null)}
      >
        {file !== null ? (
          <div
            style={{
              width: "100%",
              overflowY: "auto",
              overflowX: "auto",
              height: "95%",
            }}
          >
            <FileViewer
              fileType={file.fileType}
              filePath={getPathForFile(file.fileType, file.fileStream)}
              onError={onError}
            />
          </div>
        ) : (
          ""
        )}
        <Button
          type="button"
          variant="contained"
          className="c-btn c-btn--primary-outlined file-viewer-close-btn"
          onClick={() => setFile(null)}
        >
          {t("files.close")}
        </Button>
      </CustomModal>
      <CustomModal
        classes="files-custom-modal"
        open={openNoteModal.open}
        onCloseModal={() =>
          setOpenNoteModal({ open: false, note: null, streamId: "" })
        }
      >
        <div className="add-pattern-modal-header">
          <div className="add-pattern-modal-header-title">
            <div className="add-pattern-modal-header-title-image">
              <img src={editIcon} alt="plus" />
            </div>
            <div className="add-pattern-modal-header-title-title">
              <p>{t("files.editText")}</p>
            </div>
            <div className="add-pattern-modal-header-title-sub">
              <sub>{t("files.editTextSub")}</sub>
            </div>
          </div>
          <div
            className="add-pattern-modal-header-close"
            onClick={() =>
              setOpenNoteModal({ open: false, note: null, streamId: "" })
            }
          >
            <img src={xIcon} alt="close" />
          </div>
        </div>
        <div className="files-edit-note">
          <textarea
            className="files-note"
            value={openNoteModal.note}
            onChange={(e) =>
              setOpenNoteModal((oldState) => ({
                ...oldState,
                note: e.target.value,
              }))
            }
          />
        </div>
        <div className="files-custom-modal-buttons">
          <Button
            type="button"
            variant="contained"
            className="c-btn c-btn--primary-outlined"
            onClick={() =>
              setOpenNoteModal({ open: false, note: null, streamId: "" })
            }
          >
            {t("files.close")}
          </Button>
          <Button
            type="button"
            variant="contained"
            className="c-btn c-btn--primary"
            onClick={updateFileNoteHandler}
          >
            {t("files.saveChanges")}
          </Button>
        </div>
      </CustomModal>
      <DocsFilters
        open={toggleFiltersDrawer}
        handleClose={handleToggleFiltersDrawer}
        setPage={setPage}
        setFile={setFile}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div style={{ width: "fit-content" }}>
          <Fade in={isSearchFieldVisible} timeout={500} className="proba">
            {input}
          </Fade>
          <Fade in={isSearchFieldVisible} timeout={500}>
            <div
              style={{
                position: "absolute",
                zIndex: 10000,
                left: 780,
                top: 20,
                marginTop: 0,
              }}
            >
              <img src={searchImage} />
            </div>
          </Fade>
        </div>
        <div style={{ display: "flex" }}>
          {isSearchFieldVisible ? (
            ""
          ) : (
            <IconButton
              className="c-btn c-btn--primary-outlined candidate-btn search-field userPageBtn ml-20px no-padding custom-filter-button"
              onClick={() => setIsSearchFieldVisible(true)}
            >
              {!matches ? t("candidates.search") : null}
              <img
                src={searchImage}
                alt="filter"
                className="candidates-image"
                style={{ marginLeft: matches ? "0px" : "10px" }}
              />
            </IconButton>
          )}
          <FilterButton onShowFilters={handleToggleFiltersDrawer} />
          {!matches ? (
            <IconButton
              className="c-btn c-btn--primary-outlined candidate-btn reset-filters userPageBtn ml-20px no-padding custom-filter-button"
              onClick={resFilters}
            >
              {t("filters.resetFilters")}
            </IconButton>
          ) : (
            ""
          )}
        </div>
      </div>
      {matches ? (
        <p
          className="reset-filter-responsive"
          onClick={resFilters}
          style={{ alignSelf: "flex-end" }}
        >
          {t("filters.resetFilters")}
        </p>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          marginTop: "30px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className="table-cont"
            style={{
              display: "flex",
              width: matches ? "75vw" : "85vw",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <table
              className={"usersTable-users mini"}
              style={{
                width: file === null ? "100%" : "360px",
                overflowX: "visible",
                height: "fit-content",
              }}
            >
              <thead>
                <tr
                  className="headingRow headingRowFiles"
                  style={{ cursor: "pointer" }}
                >
                  {file === null || matches ? (
                    <th>{t("files.doucmentPath")}</th>
                  ) : (
                    ""
                  )}
                  <th>{t("files.doucmentName")}</th>
                  {file === null || matches ? (
                    <th>{t("files.doucmentType")}</th>
                  ) : (
                    ""
                  )}
                  {file === null || matches ? (
                    <th>{t("files.doucmentSize")} (kB)</th>
                  ) : (
                    ""
                  )}
                  <th>{t("files.deleteDocument")}</th>
                  {file === null || matches ? <th>{t("files.note")}</th> : ""}
                  <th>{t("files.downloadDocument")}</th>
                </tr>
              </thead>
              <tbody>
                {data.data?.map((n, index) => (
                  <tr
                    key={index}
                    className="secondaryRow"
                    onClick={() =>
                      displayFile(n.file_stream, n.stream_id, n.file_type)
                    }
                  >
                    {file === null || matches ? (
                      <td className="docs-name">{n.fileName}</td>
                    ) : (
                      ""
                    )}
                    <td className="docs-name">{n.title}</td>
                    {file === null || matches ? (
                      <td>{n.file_type && n.file_type}</td>
                    ) : (
                      ""
                    )}

                    {file === null || matches ? (
                      <td className="profession">
                        {<NumericLabel>{n.cached_file_size}</NumericLabel>}
                      </td>
                    ) : (
                      ""
                    )}
                    <td className="profession">
                      <IconButton
                        className="c-btn c-btn--primary-outlined"
                        onClick={() =>
                          setFileForDelete({
                            open: true,
                            title: n.title,
                            streamId: n.stream_id,
                          })
                        }
                      >
                        <img
                          style={{ width: "12px", height: "12px" }}
                          src={deleteIcon}
                        />
                      </IconButton>
                    </td>

                    {file === null || matches ? (
                      <td className="profession">
                        <IconButton
                          className="c-btn c-btn--primary-outlined"
                          onClick={() => {
                            setOpenNoteModal({
                              open: true,
                              note: n.note,
                              streamId: n.stream_id,
                            });
                          }}
                        >
                          <img
                            style={{ width: "12px", height: "12px" }}
                            src={editIcon}
                          />
                        </IconButton>
                      </td>
                    ) : (
                      ""
                    )}
                    <td>
                      <div onClick={stopPropagation}>
                        <a
                          className="applicant-cv-button"
                          style={{
                            width: "90px",
                            height: "40px",
                            padding: 10,
                          }}
                          download={n.title}
                          href={getPathForFile(n.file_type, n.file_stream)}
                        >
                          {t("common.download")}
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {file !== null && !matches ? (
              <div
                style={{
                  width: "750px",
                  marginLeft: "30px",
                  overflowY: "auto",
                  height: "350px",
                  backgroundColor: "#F0F0F0",
                }}
              >
                <FileViewer
                  fileType={file.fileType}
                  filePath={getPathForFile(file.fileType, file.fileStream)}
                  onError={onError}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <Pagination
            size={"small"}
            count={getTotalNumberOfPages(data)}
            color="primary"
            className="candidates-pagination"
            onChange={handleChange}
            shape="rounded"
            page={page}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "35px",
          }}
        ></div>
      </div>
    </div>
  );
};

FileTable.propTypes = {
  trigger: PropTypes.number,
};

export default FileTable;
