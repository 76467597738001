import {
  FETCH_FILES_ERR,
  FETCH_FILES_REQ,
  FETCH_FILES_SUCCESS,
  FILE_FILTERS_ERR,
  FILE_FILTERS_REQ,
  FILE_FILTERS_SUCCESS,
  UPDATE_FILTERS_EXTENSION,
  UPDATE_FILTERS_TAG,
  SET_CONTENT,
  DELETE_FILE_REQ,
  DELETE_FILE_ERR,
  DELETE_FILE_SUCCESS,
  UPDATE_FILE_REQ,
  UPDATE_FILE_ERR,
  UPDATE_FILE_SUCCESS,
  RESET_FILTERS,
} from "./fileActionConstants";

export const getFileFiltersReq = () => ({
  type: FILE_FILTERS_REQ,
});

export const getFileFiltersError = (payload) => ({
  type: FILE_FILTERS_ERR,
  payload,
});

export const getFileFiltersSuccess = (payload) => ({
  type: FILE_FILTERS_SUCCESS,
  payload,
});

export const updateFileFilterExt = (payload) => ({
  type: UPDATE_FILTERS_EXTENSION,
  payload,
});
export const updateFileFilterTag = (payload) => ({
  type: UPDATE_FILTERS_TAG,
  payload,
});

export const getFilesReq = (payload) => ({
  type: FETCH_FILES_REQ,
  payload,
});

export const getFileError = (payload) => ({
  type: FETCH_FILES_ERR,
  payload,
});

export const getFileSuccess = (payload) => ({
  type: FETCH_FILES_SUCCESS,
  payload,
});

export const setContent = (payload) => ({
  type: SET_CONTENT,
  payload,
});

export const updateFileReq = (payload) => ({
  type: UPDATE_FILE_REQ,
  payload,
});

export const updateFileError = (payload) => ({
  type: UPDATE_FILE_ERR,
  payload,
});

export const updateFileAction = (payload) => ({
  type: UPDATE_FILE_SUCCESS,
  payload,
});

export const deleteFileReq = (payload) => ({
  type: DELETE_FILE_REQ,
  payload,
});

export const deleteFileError = (payload) => ({
  type: DELETE_FILE_ERR,
  payload,
});

export const deleteFileAction = (payload) => ({
  type: DELETE_FILE_SUCCESS,
  payload,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});
