import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import uploadIcon from "../../assets/images/upload.png";
import IconButton from "../../components/IconButton/IconButton";
import { useSelector, useDispatch } from "react-redux";
// import { selectCategories } from "../../store/selectors/categoriesSelector";
import { useEffect } from "react";
import { setCategoriesReq } from "../../store/actions/categories/categoriesAction";
import {
  changeTagIsCheckedValue,
  resetIsCheckedTagsValue,
  setTagsReq,
} from "../../store/actions/tags/tagsAction";
import { selectTags } from "../../store/selectors/tagsSelector";
import { uploadFileReq } from "../../store/actions/uploadFile/uploadFileActions";
import { FILES_PAGE } from "../../constants/pages";
import PropTypes from "prop-types";
import TreeViewFiles from "../../components/Files/TreeViewFiles";
import Button from "../../components/Button/Button";

const AddFile = ({ history }) => {
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [title, setTitle] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  // const categories = useSelector(selectCategories);
  const tags = useSelector(selectTags);
  const [selectedCategory, setSelectedCategory] = useState({
    id: -1,
    name: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      setCategoriesReq({
        parentCategoryId: undefined,
        onNull: onNullCategories,
      })
    );
    dispatch(setTagsReq());
  }, []);

  const onNullCategories = () => {
    history.push(FILES_PAGE);
  };

  const onSuccessUploadFile = () => {
    dispatch(resetIsCheckedTagsValue());
    setPdfFile(null);
    setTitle("");
    setShowMessage(true);
    setSelectedCategory({ id: -1, name: "" });
    setDropzoneActive(false);
  };

  useEffect(() => {
    if (showMessage === true) {
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    }
  }, [showMessage]);

  const handleDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    setTitle(selectedFile.name.split(".")[0]);
    setPdfFile(selectedFile);
  };

  const handleChangeInputFile = (e) => {
    const selectedFile = e.target.files[0];
    setTitle(selectedFile.name.split(".")[0]);
    setPdfFile(selectedFile);
  };

  const selectCategoryHandler = (category) => {
    setSelectedCategory(category);
  };

  const onChangeTagsCheckbox = (id) => {
    dispatch(changeTagIsCheckedValue(id));
  };

  const uploadFileHandler = () => {
    const tagsIds = tags
      .filter((tag) => tag.isChecked === true)
      .map((tag) => Number(tag.id));

    if (title === "" || tagsIds.length === 0 || pdfFile === null) return;

    dispatch(
      uploadFileReq({
        title,
        categoryId: selectedCategory.id,
        tagsIds,
        fileToUpload: pdfFile,
        onSuccessUploadFile,
      })
    );
  };

  return (
    <div
      className="uploadCV-input files-page-drag-and-drop"
      style={{
        backgroundColor:
          !pdfFile && dropzoneActive === true ? "#F4F4F4" : "#ffffff",
      }}
      onDragOver={(e) => {
        setDropzoneActive(true);
        e.preventDefault();
      }}
      onDragLeave={(e) => {
        setDropzoneActive(false);
        e.preventDefault();
      }}
      onDrop={(e) => handleDrop(e)}
    >
      <div className="files-page" style={{ paddingTop: "0px" }}>
        <div className="l-t-rectangle"></div>
        <div className="r-b-rectangle"></div>

        <div className="files-page-card">
          <div className="files-page-card-title">
            <h1>{t("files.addFileCategories")}</h1>
          </div>
          <div className="files-page-card-content">
            <TreeViewFiles onSelectCategory={selectCategoryHandler} />
          </div>
        </div>

        <div className="files-page-card">
          <div className="files-page-card-title">
            <h1>{t("files.addFileSelectedCategory")}</h1>
          </div>
          <div className="files-page-card-input">
            <input
              type="text"
              disabled={true}
              value={selectedCategory.id !== -1 ? selectedCategory.name : ""}
            />
            <Button
              type="button"
              variant="contained"
              className="c-btn c-btn--primary"
              onClick={() => setSelectedCategory({ id: -1, name: "" })}
              disabled={selectedCategory.id === -1}
            >
              {t("files.addFileSetRoot")}
            </Button>
          </div>
        </div>

        <div className="files-page-card">
          <div className="files-page-card-title">
            <h1>{t("files.addFileTags")}</h1>
          </div>
          <div className="files-page-card-content">
            <div className="files-page-card-content-checkboxes">
              {tags &&
                tags.map((tag) => (
                  <FormControlLabel
                    key={tag.id}
                    control={
                      <Checkbox
                        value={tag.name}
                        checked={tag.isChecked}
                        onChange={onChangeTagsCheckbox.bind(this, tag.id)}
                        className="create-ad-second-step-checkbox"
                      />
                    }
                    label={tag.name}
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="files-page-card">
          <div className="files-page-card-title">
            <h1>{t("files.addFileDragAndDrop")}</h1>
          </div>
          <div
            className="files-page-card-content drag-and-drop"
            style={{
              backgroundColor: dropzoneActive ? "#F4F4F4" : "#ffffff",
            }}
          >
            <div className="uploadCV-input-sub-container">
              <img src={uploadIcon} />
              <div className="uploadCV-input-sub-container">
                {pdfFile !== null ? (
                  <p style={{ maxWidth: "80%" }}>{pdfFile.name}</p>
                ) : (
                  <>
                    <p>
                      {t("ads.dragPdf1")} <br></br>
                      <label
                        htmlFor="upload-file"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          color: "#1E92D0",
                        }}
                      >
                        {t("common.search")}
                      </label>
                      &nbsp;{t("ads.dragPdf2")}
                    </p>
                    <input
                      type="file"
                      name="photo"
                      id="upload-file"
                      style={{ display: "none", zIndex: -1 }}
                      value={pdfFile}
                      data-testid="apply-for-ad-modal-fourth-stage-pdf-input"
                      onChange={handleChangeInputFile}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="add-file-message"
          style={
            showMessage === false ? { display: "none" } : { display: "flex" }
          }
        >
          <p>{t("files.addFileSuccessfullyAddedFile")}</p>
        </div>

        <div className="files-page-card">
          <div
            className="files-page-card-add-button"
            style={{ alignItems: "center", position: "relative" }}
          >
            <p
              className="applicant-ads-back-button"
              onClick={() => history.push(FILES_PAGE)}
            >
              {t("files.addFileGoBackToFiles")}
            </p>
            <IconButton
              className="c-btn ads-page-btn c-btn--primary add-ad-btn"
              onClick={uploadFileHandler}
            >
              + {t("files.addFile")}
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

AddFile.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};

export default AddFile;
