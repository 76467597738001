import createReducer from "../../utils/createReducer";
import {
  DELETE_USER_ERR,
  DELETE_USER_SUCCESS,
  FETCH_USERS_ERR,
  FETCH_USERS_SUCCESS,
  TOGGLE_USER_ENABLE_ERR,
  TOGGLE_USER_ENABLE_SUCCESS,
} from "../../actions/users/usersActionConstants";
// import { setUsersError } from "../../actions/users/usersActions";

const initialState = {
  users: [],
  selected: {},
  fetchUsersErrorMessage: "",
  toggleEnableErrorMessage: ""
};

export default createReducer(
  {
    [FETCH_USERS_SUCCESS]: setStateUsers,
    [FETCH_USERS_ERR]: setUsersErrorMessage,
    [TOGGLE_USER_ENABLE_SUCCESS]: toggleEnableStateUsers,
    [TOGGLE_USER_ENABLE_ERR]: setToggleEnableErrorMessage,
    [DELETE_USER_SUCCESS]: deleteStateUser,
    [DELETE_USER_ERR]: setDeleteUserErrorMessage,
  },
  initialState
);

function setStateUsers(state, action) {
  return {
    ...state,
    users: action.payload,
  };
}

function setUsersErrorMessage(state, action) {
  return {
    ...state,
    fetchUsersErrorMessage: action.payload,
  };
}

function toggleEnableStateUsers(state, action) {
  let newUsers = state.users.map(n => n.id === action.payload ? {...n, isEnabled: !n.isEnabled} : n)
  return {
    ...state,
    users: newUsers,
  };
}

function setToggleEnableErrorMessage(state, action) {
  return {
    ...state,
    toggleEnableErrorMessage: action.payload,
  };
}

function deleteStateUser(state, action) {
  let newUsers = state.users.filter(n => n.id !== action.payload)
  return {
    ...state,
    users: newUsers,
  };
}

function setDeleteUserErrorMessage(state, action) {
  return {
    ...state,
    toggleEnableErrorMessage: action.payload,
  };
}
