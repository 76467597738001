import createReducer from "../../utils/createReducer";
import {
  SET_USER,
  SET_USER_ERROR,
  RESET_USER_STATE,
} from "../../actions/user/userActionConstants";

const initialState = {
  user: {
    id: "",
    firstName: "",
    lastName: "",
    username: "",
    token: "",
    refreshToken: "",
  },
  errorMessage: "",
};

export default createReducer(
  {
    [SET_USER]: setUser,
    [SET_USER_ERROR]: setUserError,
    [RESET_USER_STATE]: resetUserState,
  },
  initialState
);

function setUser(state, action) {
  return {
    ...state,
    user: action.payload,
  };
}

function resetUserState() {
  return initialState;
}

function setUserError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
