import {
  UPLOAD_FILE_REQ,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERR,
} from "./uploadFileActionConstants";

export const uploadFileReq = (payload) => ({
  type: UPLOAD_FILE_REQ,
  payload,
});

export const uploadFileError = (payload) => ({
  type: UPLOAD_FILE_ERR,
  payload,
});

export const uploadFile = (payload) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload,
});
