import React, { useState } from "react";
import PropTypes from "prop-types";
import avatar from "../../assets/images/Avatar.png";
import filters from "../../assets/images/filters.png";
import delete2 from "../../assets/images/delete.png";
import lock from "../../assets/images/lock.png";
2;
import forbiden from "../../assets/images/forbiden.png";
import IconButton from "../../components/IconButton/IconButton";
import { Link, useParams } from "react-router-dom";
import {
  deleteUserReq,
  setEnableUsersReq,
  userDetailsReq,
} from "../../store/actions/users/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { USERS_PAGE } from "../../constants/pages";
import { forgetPassword } from "../../store/actions/login/loginActions";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  useMediaQuery,
} from "@mui/material";
import ConfirmDialog from "../../components/MUI/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { USER_DETAILS_LOADING } from "../../store/actions/users/usersActionConstants";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import {
  selectChangedCategories,
  selectIsCategoriesChecked,
} from "../../store/selectors/categoriesSelector";
import {
  changeIsCheckedCategory,
  setGrantCategoriesReq,
  setIsCategoriesCheckedReq,
} from "../../store/actions/categories/categoriesAction";
import Button from "../../components/Button/Button";

const UserDetails = ({ history }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("371"));
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showConfirm, setConfirm] = useState(false);
  const [showReset, setReset] = useState(false);
  const [showDelete, setDelete] = useState(false);
  const { t } = useTranslation();
  const categories = useSelector(selectIsCategoriesChecked);
  const changedCategories = useSelector(selectChangedCategories);

  const { user, errorMessage } = useSelector((s) => s.userDetails);

  const handleReset = (email) => {
    dispatch(
      forgetPassword({
        email,
        handleApiResponseSuccess: handleApiResponseSuccessReset,
      })
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(setIsCategoriesCheckedReq({ userId: id }));
    }
  }, [id]);

  const handleApiResponseSuccessReset = () => {
    setReset(false);
  };

  const enableHandler = () => {
    dispatch(
      setEnableUsersReq({
        id: user.id,
        handleApiResponseSuccess: handleApiResponseSuccessEnable,
      })
    );
  };

  const handleApiResponseSuccessEnable = () => {
    setConfirm(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteUserReq({
        id,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
      })
    );
  };

  const handleApiResponseSuccessDelete = () => {
    history.push({
      pathname: USERS_PAGE,
      state: {
        from: history.location.pathname,
      },
    });
  };

  useEffect(() => {
    dispatch(userDetailsReq({ id }));
  }, [dispatch]);

  const isLoading = useSelector(
    selectIsLoadingByActionType(USER_DETAILS_LOADING)
  );

  const handleChangingCategoriesIsChecked = (id, isChecked) => {
    dispatch(changeIsCheckedCategory({ id, isChecked }));
  };

  const onGrantCategoriesHandler = () => {
    dispatch(
      setGrantCategoriesReq({ userId: id, categories: changedCategories })
    );
  };

  return isLoading ? (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <div className="loader-container h-withHeader">
        <span>Loading</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="l-t-rectangle"></div>
      <div className="r-b-rectangle"></div>
      <ConfirmDialog
        open={showReset}
        title={t("users.resetPassword")}
        subtitle={user?.firstName + " " + user?.lastName}
        imgSrc={lock}
        content={t("users.resetLink")}
        onClose={() => {
          setReset(false);
        }}
        onConfirm={() => {
          handleReset(user.email);
          // setConfirm(false)
        }}
      />
      <ConfirmDialog
        open={showConfirm}
        title={t("users.disableUser")}
        subtitle={user?.firstName + " " + user?.lastName}
        imgSrc={forbiden}
        content={t("users.questionDisableUser")}
        onClose={() => {
          setConfirm(false);
        }}
        onConfirm={() => {
          enableHandler(user.id);
          // setConfirm(false)
        }}
      />
      <ConfirmDialog
        open={showDelete}
        title={t("users.deleteUser")}
        subtitle={user?.firstName + " " + user?.lastName}
        imgSrc={delete2}
        content={t("users.questionDeleteUser")}
        onClose={() => {
          setDelete(false);
        }}
        onConfirm={() => {
          deleteHandler(user.id);
          // setConfirm(false)
        }}
      />
      <div className="pl-144 pt-36px">
        {errorMessage ? (
          errorMessage
        ) : (
          <>
            <div className="divider">
              <div className="flex-center">
                <h1 style={{ letterSpacing: "1px" }}>{t("users.user")}</h1>
                <div
                  className="vr"
                  style={{
                    margin: "0px 10px 0px 15px",
                    top: "6px",
                    backgroundColor: "#252525",
                  }}
                ></div>
                <h3
                  style={{
                    letterSpacing: "0.75px",
                    position: "relative",
                    top: "4px",
                  }}
                  className="text-blue"
                >
                  {user && user.firstName} {user && user.lastName}
                </h3>
              </div>
              <div className="flex-center">
                <IconButton
                  className={
                    "c-btn--primary-outlined c-btn userPageBtn ml-20px no-padding"
                  }
                  onClick={() => setReset(true)}
                >
                  {!matches && t("users.resetPassword")}
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                      paddingLeft: !matches && "10px",
                    }}
                    src={lock}
                  />
                </IconButton>
                {!matches && (
                  <IconButton
                    className={`c-btn--primary-outlined c-btn userPageBtn ml-20px no-padding ${
                      user?.isEnabled ? "activeEnable" : "deactiveEnable"
                    }`}
                    onClick={() => setConfirm(true)}
                  >
                    {user?.isEnabled ? t("users.block") : t("users.unblock")}
                    <img
                      style={{
                        position: "relative",
                        top: -0.25,
                        paddingLeft: "10px",
                      }}
                      src={forbiden}
                    />
                  </IconButton>
                )}
                {!matches && (
                  <IconButton
                    className={
                      "c-btn--primary-outlined c-btn userPageBtn ml-20px no-padding"
                    }
                    onClick={() => setDelete(true)}
                  >
                    {t("common.delete")}
                    <img
                      style={{
                        position: "relative",
                        top: -0.25,
                        paddingLeft: "10px",
                      }}
                      src={delete2}
                    />
                  </IconButton>
                )}
                <IconButton
                  className={
                    "c-btn--primary-outlined c-btn userPageBtn ml-20px no-padding"
                  }
                >
                  {!matches && t("users.profile")}
                  <img
                    style={{
                      position: "relative",
                      top: -0.25,
                      paddingLeft: !matches && "10px",
                    }}
                    src={filters}
                  />
                </IconButton>
              </div>
            </div>
            <div
              className="user-details-middle"
              style={{
                display: "flex",
                flexDirection: matches ? "column" : "row",
                marginTop: matches ? "0px" : "20px",
              }}
            >
              <div>
                <div
                  className="flex-center"
                  style={{ justifyContent: "flex-start" }}
                >
                  <img
                    src={avatar}
                    height="108px"
                    width="108px"
                    style={{ margin: "18px 15px 36px 0px" }}
                  />
                  <p>
                    {user?.position
                      ? user.position
                      : t("users.positionNotDeclared")}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "18px",
                  }}
                >
                  <p style={{ fontWeight: "600" }}>{t("users.contact")}</p>
                  <div
                    className="flex-center"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <p style={{ width: "85px" }}>Email:</p>
                    <p className="text-blue">{user && user.email}</p>
                  </div>
                  <div
                    className="flex-center"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <p style={{ width: "85px" }}>{t("users.phone")}:</p>
                    <p className="text-blue">
                      {user?.phoneNumber
                        ? user.phoneNumber
                        : t("users.noPhoneNumber")}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "18px",
                    paddingTop: "36px",
                  }}
                >
                  <p style={{ fontWeight: "600" }}>{t("users.socials")}</p>
                  <div
                    className="flex-center"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <p style={{ width: "85px" }}>LinkedIn:</p>
                    <p className="text-blue">
                      {user?.linkedIn
                        ? user.linkedIn
                        : t("users.noSocialMedia")}
                    </p>
                  </div>
                </div>
                {!matches && (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "flex-start",
                      marginTop: "150px",
                    }}
                  >
                    <Link to={"/users"} className="text-blue">
                      {t("users.backToUsers")}
                    </Link>
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1rem",
                    marginTop: matches ? "1rem" : "0px",
                  }}
                >
                  <h1>{t("users.grantedCategories")}</h1>
                </div>
                <div className="user-details-middle-checkboxes">
                  <FormGroup>
                    {categories?.map((category, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            onChange={handleChangingCategoriesIsChecked.bind(
                              this,
                              category.id,
                              category.isChecked
                            )}
                            value={category.name}
                            checked={category.isChecked}
                            className="ad-filters-checkbox"
                          />
                        }
                        label={category.name}
                      />
                    ))}
                  </FormGroup>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      disabled={
                        !changedCategories || changedCategories.length === 0
                      }
                      sx={{ mt: 3, mb: 2 }}
                      fullWidth
                      className="c-btn c-btn--primary w-289 f"
                      type="submit"
                      onClick={onGrantCategoriesHandler}
                    >
                      {t("users.applyChanges")}
                    </Button>
                  </div>
                  {matches && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <Link
                        to={"/users"}
                        className="text-blue"
                        style={{ fontSize: "1.1rem" }}
                      >
                        {t("users.backToUsers")}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetails;

UserDetails.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
