import createReducer from "../../utils/createReducer";
import {
  USER_DETAILS_ERR,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_TOGGLE,
} from "../../actions/users/usersActionConstants";
// import { setUsersError } from "../../actions/users/usersActions";

const initialState = {
  user: {},
  errorMessage: "",
};

export default createReducer(
  {
    [USER_DETAILS_SUCCESS]: setStateUserDetails,
    [USER_DETAILS_ERR]: setUserDetailsErrorMessage,
    [USER_DETAILS_TOGGLE]: toggleUserDetails,
  },
  initialState
);

function setStateUserDetails(state, action) {
  return {
    ...state,
    user: action.payload,
  };
}

function setUserDetailsErrorMessage(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function toggleUserDetails(state) {
  return state.user
    ? {
        ...state,
        user: { ...state.user, isEnabled: !state.user.isEnabled },
      }
    : {
        ...state,
      };
}
