import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  setTags,
  setTagsError,
  setCreateTag,
  createTagError,
} from "../actions/tags/tagsAction";
import { authScopeStringGetHelper } from "../../util/helpers/authScopeHelpers";
import { JWT_TOKEN } from "../../constants/localStorage";
import { addHeaderToken } from "../../request";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { FETCH_TAGS_REQ,CREATE_TAG_REQ } from "../actions/tags/tagsActionConstants";
import { getAllTags } from "../../request/tagsRequest";
import { getFileFilters, createTagRequest } from "../../request/tagsRequest";
import { FILE_FILTERS_REQ } from "../actions/files/fileActionConstants";
import {
  getFileFiltersError,
  getFileFiltersSuccess,
} from "../actions/files/fileActions";

export function* getTags() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getAllTags);
    const resultData = result.data.map((tag) => ({ ...tag, isChecked: false }));
    yield put(setTags(resultData));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(setTagsError(errorMessage));
  }
}

export function* getFileFiltersGen() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    const result = yield call(getFileFilters);
    yield put(getFileFiltersSuccess(result.data));
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(getFileFiltersError(errorMessage));
    }
  }
}

export function* createTagSaga({ payload }) {
  console.log("Payload je to",payload)
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    yield call(addHeaderToken, JwtToken);
    yield call(createTagRequest, {
      name: payload.name,
    });
    yield put(setCreateTag());
    if (payload.onSuccess) yield call(payload.onSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(createTagError(errorMessage));
  }
}

export default function* tagsSaga() {
  yield all([takeLatest(FETCH_TAGS_REQ, getTags)]);
  yield all([takeEvery(FILE_FILTERS_REQ, getFileFiltersGen)]);
  yield all([takeEvery(CREATE_TAG_REQ, createTagSaga)]);
}
