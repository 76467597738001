import { all, call, put, takeEvery } from "redux-saga/effects";
import { register } from "../../request/registerRequest";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { REGISTER_REQUEST } from "../actions/register/registerActionConstants";
import { registerError, registerSuccess } from "../actions/register/registerActions";

export function* registerUser({payload}) {
  try {
    yield call(register, payload.model);
    yield put(registerSuccess());
    if(payload.handleApiResponseSuccess){
        yield call(payload.handleApiResponseSuccess)
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, error);
      yield put(registerError(errorMessage));
    }
  }
}

export default function* registerSaga() {
  yield all([
    takeEvery(REGISTER_REQUEST, registerUser),
  ]);
}
