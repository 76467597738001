import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { refreshUserToken } from "./store/actions/login/loginActions";
import { useLocation } from "react-router-dom";

import {
  FORGOT_PASSWORD_PAGE,
  FORGOT_PASSWORD_CONFIRMATION_PAGE,
  NOT_FOUND_PAGE,
  ERROR_PAGE,
  BASE_PAGE,
  RESET_PASSWORD_PAGE,
  USERS_PAGE,
  USER_DETAILS_PAGE,
  ADD_FILE,
  FILES_PAGE,
  FILES_DEPTH_PAGE,
  REGISTER_PAGE,
} from "./constants/pages";

import LoginPage from "./pages/LoginPage/LoginPageMUI";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage";
import ErrorPage from "./pages/ErrorPages/ErrorPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPageMUI";
import PrivateRoute from "./components/Router/PrivateRoute";
import ForgotPasswordConfirmationPage from "./pages/ForgotPasswordPage/ForgotPasswordConfirmationPageMUI";
import ResetPasswordPage from "./pages/ForgotPasswordPage/ResetPasswordPageMUI";
import UsersPage from "./pages/UsersPage/UsersPage";
import UserDetails from "./pages/UsersPage/UserDetails";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import AddFile from "./pages/FilesPage/AddFile";
import FilesPage from "./pages/FilesPage/FilesPage";

const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === BASE_PAGE) {
      return;
    }
    dispatch(refreshUserToken());
  }, [location]);
  return (
    <Switch>
      <Route exact path={BASE_PAGE} component={LoginPage} />
      <Route path={NOT_FOUND_PAGE} component={NotFoundPage} />
      <Route path={ERROR_PAGE} component={ErrorPage} />
      <Route path={FORGOT_PASSWORD_PAGE} component={ForgotPasswordPage} />
      <Route
        path={FORGOT_PASSWORD_CONFIRMATION_PAGE}
        component={ForgotPasswordConfirmationPage}
      />
      <Route exact path={REGISTER_PAGE} component={RegisterPage} />
      <Route path={RESET_PASSWORD_PAGE} component={ResetPasswordPage} />
      <PrivateRoute exact path={USER_DETAILS_PAGE} component={UserDetails} />
      <PrivateRoute exact path={USERS_PAGE} component={UsersPage} />
      <PrivateRoute exact path={ADD_FILE} component={AddFile} />
      <PrivateRoute exact path={FILES_PAGE} component={FilesPage} />
      <PrivateRoute exact path={FILES_DEPTH_PAGE} component={FilesPage} />
      <Redirect from="*" to={NOT_FOUND_PAGE} />
    </Switch>
  );
};

export default AppRoutes;
